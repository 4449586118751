import React,{ useEffect,useState } from 'react';
import { Row, Col, Card, Table, Pagination } from 'react-bootstrap';
import {DateHandler} from '../../../../CoolHelpers/CoolHelpers';
import ProductsService from '../../../../api/user/ProductsService';
import TransactionModal from '../TransactionModal';
import {currencyFixer, interestEarned, netContributions, netTotal, pendingIn, pendingOut} from './Utils';

 const SavingsAccount = (props) => {
    const [state,setState] = useState({});
      const [countP,setCountP] = useState([])
      const [page,setPage] = useState(1);
      const [pages,setPages] = useState(Infinity);
     const [transaction, setTransaction] = useState({});
     const [showTransactionModal,setShowTransactionModal] = useState(false);
     const paginationHandler =(pages)=>{
      let active = state.active;
      setPages(pages);
      let paginationItems = [];
      for (let number = 1; number <= pages; number++) {
      paginationItems.push(
      <Pagination.Item key={number} active={number === active} onClick={(x)=>setPage(number)}>
          {number}
      </Pagination.Item>,
      );
      }
      setCountP(paginationItems);
      };

    useEffect(()=>{
        setState({...state,loading:true});
        if(props._id){
            const transactions =  ProductsService.getUserSavingsTransactions(props._id,page,5);
            transactions?.then((x)=>{
                let pages = Math.floor(x.data.transactionCount/5) === 0 ? 1 :
               (x.data.transactionCount%5) > 0 ? Math.floor(x.data.transactionCount/5) + 1 : Math.floor(x.data.transactionCount/5); 
                paginationHandler(pages);
                setState({loading:false,error:false,transactions:x.data.transactions});
            })
            transactions?.catch((x)=>{
                setState({...state,error:x,loading:false})
            });
        };
    },[props._id, page]);   

    return (
        <>
         <Row>
            <Col lg="3">
                <div>
                  <h5>Net Contributions</h5>
                  {netContributions(props?.balance,props?.interestBalance, props.accountMainCurrency)}
                </div>
            </Col>
            <Col lg="2">
                <div>
                  <h5>Net Balance</h5>
                  {netTotal(props.balance, props.pendingOut,props.accountMainCurrency)}
                </div>
            </Col>
            <Col lg="3">
                <div>
                  <h5>Interest Earned (@{props.product?.interestRatePerAnnum}% p/a)</h5>
                   {interestEarned(props.interestBalance,props.accountMainCurrency)}
                </div>
            </Col>  
            <Col lg="2">
                 <div>
                    <h5>Pending In</h5>
                    { pendingIn(props.pendingIn,props.accountMainCurrency)}
                </div>
            </Col>
            <Col lg="2">
                <div>
                    <h5>Pending Out</h5>
                    {pendingOut(props.pendingOut, props.accountMainCurrency)}
                </div>
            </Col>
        </Row>
        <Row>
            <Card>
                <Card.Body>
                    <Col>
                    <div className="table-responsive">
                        <Table>
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Reference #</th>
                                    <th scope="col">Created On</th>
                                    <th scope="col">Approved On</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{height:'20px',overflow:'scroll'}}>
                                {(state.transactions || []).map((record, index) => {
                                    return (
                                        <tr key={index} onClick={()=>{
                                            setTransaction(record);
                                            setShowTransactionModal(true);
                                            }}
                                            style={{cursor:'pointer'}}
                                            >
                                            <th scope="row">{record.transactionId}</th>
                                            <td>{DateHandler(record.dateCreated)}</td>
                                            <td>{record?.dateApproved ? DateHandler(record?.dateApproved) : 'Pending'}</td>
                                            <td style={{width:'150px'}}><span style={{
                                                backgroundColor:props._id === record.to ? record.status === 'Pending' ? 'orange' : 'green':record.status === 'Pending' ? 'orange' : 'red',
                                                color:'#fff',padding:3,borderRadius:3,
                                                }}>
                                                {record.transactionType}</span></td>
                                            <td style={{width:'250px'}}>{record.currency === "Rand" ? "ZAR" : record.currency} {currencyFixer(record?.amount)}</td>
                                            <td>{record.status === "Rejected" ?
                                            <span style={{
                                                backgroundColor:'brown',
                                                color:'#fff',padding:3,borderRadius:3,
                                                }}>
                                                {record.status}</span> : record.status
                                                    }</td>
                                            <td>{props._id === record.to ?'Incoming':'Outgoing'}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <Pagination size="sm" >
                        { page > 1 && <Pagination.Prev  onClick={()=>setPage(page-1)}/>}
                        {countP}
                        {(page < pages) && <Pagination.Next onClick={()=>setPage(page+1)}/>}
                        </Pagination>
                    </div>
                    </Col>
                </Card.Body>
            </Card>
        </Row>
           <TransactionModal
            show={showTransactionModal}
            onHide={() => setShowTransactionModal(false)}
            {...transaction}
           />
        </>
    );
};

export default SavingsAccount;