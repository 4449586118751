import React from 'react'
import { Card } from 'react-bootstrap';
import PaymentSelectionModal from '../dashboard/Analytics/Overview/PaymentSelectionModal';

const DepositToFunding = (props) => {
    return (
        <Card style={{marginTop:20}}>
            <Card.Body>
                <PaymentSelectionModal {...props} />
            </Card.Body>
        </Card>
    )
}

export default DepositToFunding;