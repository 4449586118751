import React from 'react';
import { Redirect } from 'react-router-dom';
import { isUserAuthenticated } from '../api/user/Cookie';

const Root = () => {

    const getRootUrl = () => {
        
        let url: string;

        // check if user logged in or not and return url accordingly
        if (isUserAuthenticated() === false) {
            url = 'redirect/auth';
        }else{
            url =  'dashboard/home'
        }
        return url;
    };

    const url = getRootUrl();

    return <Redirect to={`/${url}`} />;
};

export default Root;
