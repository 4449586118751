import React, { useState } from 'react'
import { Alert, Button, Row, Col } from 'react-bootstrap'
import { get } from 'react-hook-form'
import { getCurrency, getDecodedUser, getEntityCountry, getLoggedInUser } from '../../api/user/Cookie'
import ProductsService from '../../api/user/ProductsService'
import { FormInput, VerticalForm } from '../../components'
import FileUploader from '../../components/FileUploader'
import Spinner from '../../components/Spinner'
import { calculateCharge } from '../../CoolHelpers/CoolHelpers'

const WithdrawFrom = (props) => {
    const [state, setState] = useState({ to: 'bank' });
    const user = getDecodedUser();
    const country = getEntityCountry();

    const handleSubmit = (x) => {

        x.amount = parseFloat(x.amount);
        x.owner = user.id;
        x.currency = getCurrency();

        if (props?._id) {
            let balance, pendingOut;
            // balance = props?.netTotal - calculateCharge(x.amount, props.product.withdrawalCharge);
            balance = props.balance + props.interestBalance;
            pendingOut = props?.pendingOut;

            if (((balance ? balance : 0) - (pendingOut ? pendingOut : 0) - x.amount) >= 0 && x.amount > 0) {
                const query = ProductsService.withdrawFromSavingsAccount(x, props._id, country);
                query.then((x) => {
                    setState({ loading: false, error: 'Withdrawal Request Submitted Succesfully' });
                    document.location.reload();
                });

                query.catch((x) => {
                    setState({ loading: false, error: x });
                });
            } else {
                setState({ ...state, error: 'Insufficient Funds' });
            }

        }
    };


    return (
        <>
            {
                state.error && <Alert>
                    {state.error}
                </Alert>
            }
            <VerticalForm
                onSubmit={handleSubmit}
            >
                <FormInput
                    type="select"
                    name="withdrawalTo"
                    label="Choose Where you would like to withdraw these funds to"
                    containerClass='mb-3'
                    onChange={(x) => {
                        setState({ ...state, to: x.target.value });
                    }}
                >
                    <option value="bank">
                        Withdraw To My Bank
                    </option>
                    <option value="suspense">
                        Withdraw To My Suspense Account
                    </option>
                </FormInput>
                <FormInput
                    type="number"
                    label="Enter Amount"
                    containerClass='mb-3'
                    placeholder='0.00'
                    name="amount"
                    onChange={(x) => {
                        setState({ ...state, amount: x.target.value });
                    }}
                />
                {state.to === "bank" &&

                    <p>This Transaction will incure a charge of <strong> {props.accountMainCurrency} {calculateCharge(state.amount, props?.product?.withdrawalCharge)} </strong></p>}
                <Button
                    type="submit"
                    style={{
                        backgroundColor: '#47878A', color: '#fff', fontSize: 13, border: 'none'
                    }}
                    disabled={state.loading}
                >

                    {
                        state.loading ? <Spinner></Spinner> : 'Withdraw From Account'
                    }

                </Button>
            </VerticalForm>
        </>

    )
}

export default WithdrawFrom