import React,{useEffect,useState} from 'react';
import { Card, Dropdown, Row, Col, Tab, Nav, Button, TabPane } from 'react-bootstrap';
import {Prev} from 'react-bootstrap/esm/PageItem';
import {getEntityCountry, getGlobalSettings, isUserAuthenticated} from '../../../../api/user/Cookie';
import ProductsService from '../../../../api/user/ProductsService';
import FundSavings from './FundSavings';
import SavingsAccount from './SavingsAccount';
import WithdrawFromSavings from './WithdrawFromSavings';

// components

const Product= (props) => {
    const [state, setState] = useState();
    const country = getEntityCountry();
    const globalSetting = getGlobalSettings();

   // const usersCanTransact = globalSetting.usersCanTransact === "true" ? true : false;
    const usersCanTransact = !props.isSecondary || props.permissions.includes("withdraw")
    
    useEffect(()=>{

        if(isUserAuthenticated() && country){
        const savings = ProductsService.getUserSavingsAccount(country);
        
        savings.then((x)=>{

            setState({
                ...x.data.account,
                pendingIn:x.data?.pendingIn[0]?.totalAmount,
                pendingOut:x.data?.pendingOut[0]?.totalAmount,
            });

            const savingsBalance = (x.data?.account?.balance || 0) - (x.data?.pendingOut[0]?.totalAmount || 0);

            props.setState((prev)=>{return {...prev, savingsAccount:x.data.account,savingsBalance:savingsBalance}});

        });
        savings.catch((x)=>{
            setState({loading:false,feedback:x,...state})
        });
        }
    },[])


    return (
        <Card style={{border:'none',marginBottom:50,overflowX:'scroll',height:'auto'}}>
            <Card.Body >
                <h4 className="card-title header-title">{props.accountType} Account</h4>
                <Tab.Container id="left-tabs-example"  defaultActiveKey="savings">
                    <Row className='handle-bar-selection-summary'>
                        <Col>
                            <Nav as="ul" variant="tabs" className="d-block d-sm-flex" style={{justifyContent:'space-between'}}>
                                <div style={{display:'flex',marginTop:20}}>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="savings">
                                     <Button style={{backgroundColor:'#323129',border:'1px solid #323129'}}>Account Summary</Button>
                                    </Nav.Link>
                                </Nav.Item>
                                </div>
                                {usersCanTransact && <div style={{display:'flex',marginTop:20}}>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="withdraw">
                                        <Button>Withdraw</Button>
                                    </Nav.Link>
                                </Nav.Item>
                                </div>}
                            </Nav>
                        </Col>
                    </Row>
                    
                       <Row style={{marginBottom:50}} className="account-option-select">
                        <Col>
                            <Nav as="ul" variant="tabs">
                                <div style={{display:'flex',marginTop:20,justifyContent:'space-between',width:'100%'}}>
                                <Nav.Item as="li">
                                    <Nav.Link eventKey="savings">
                                     <Button style={{backgroundColor:'#323129',border:'1px solid #323129'}}>Summary</Button>
                                    </Nav.Link>
                                </Nav.Item>
                                {usersCanTransact && <Nav.Item as="li">
                                    <Nav.Link eventKey="withdraw">
                                        <Button>Withdraw</Button>
                                    </Nav.Link>
                                </Nav.Item>}
                                </div>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{height:400}}>
                            <Tab.Content style={{height:'100%'}}>
                                <Tab.Pane eventKey="savings" style={{height:'100%'}} >
                                   <SavingsAccount {...state} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="deposit" style={{height:'100%'}} >
                                    <FundSavings {...state} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="withdraw" style={{height:'100%'}} >
                                    <WithdrawFromSavings {...state} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                    
                </Tab.Container>
                               
                
            </Card.Body>

        </Card>
    );
};

export default Product;
