import React, {useState} from 'react'
import {Alert, Button, Row, Col} from 'react-bootstrap'
import {getCurrency, getDecodedUser, getEntityCountry, getLoggedInUser} from '../../../../api/user/Cookie'
import ProductsService from '../../../../api/user/ProductsService'
import {FormInput, VerticalForm} from '../../../../components'
import FileUploader from '../../../../components/FileUploader'
import Spinner from '../../../../components/Spinner'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {calculateCharge} from '../../../../CoolHelpers/CoolHelpers'
import AccountsService from '../../../../api/AccountsService'

const DepositToAccount = (props) => {
    const [state,setState] = useState({});
    const user = getDecodedUser();
    const country = getEntityCountry();
    const { t } = useTranslation();
    
    const handleSubmit=(x)=>{

        setState({...state,loading:true});

        x.amount = parseFloat(x.amount);
        x.owner = user.id;
        x.transactionType = "Deposit";
        x.feeType = "EFTDEPOSIT";
        x.currency = getCurrency()
        x.to = props?.id;
        x.country = country;


        var formData = new FormData();
        (state.files || []).map((file)=>{
            formData.append(`supportingDocuments`, file);
        });
        
        for(var key in x){
        formData.append(key,x[key]);
        }

        if(props?.id){

        const query = AccountsService.depositToSuspenseAccount(formData);

        query.then((x)=>{
            setState({error:'Deposit Request is successfull'});
            document.location.reload();
        });

        query.catch((x)=>{
            setState({loading:false,error:x});
        })
        }
    };

   const schemaResolver = yupResolver(
        yup.object().shape({
            amount: yup.string().required(t('Please enter amount')),
        })
    );


  return (
    <>
    {!props.displayBack && <Button
       onClick={props.back}
       style={{ marginBottom: 20, backgroundColor: '#F1F5F9', fontSize: 13, border: 'none' ,color:'#000'}}
       >
        Back
    </Button>}
    {
        state.error && <Alert>
            {state.error}
        </Alert>
    }
    <p style={{marginBottom:20,color:'#3b3b3b'}}>
            Funds may take a while to reflect in your account as we verify your transfer.
        </p>
        <p style={{marginBottom:20,color:'#3b3b3b',fontSize:13}}>Banking Details</p>
        <ul style={{marginBottom:20}}>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Bank: First National Bank (FNB)
            </li>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Account Name: Tsigiro Usekelo (Pty) Ltd
            </li>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Account Number: 63043218436
            </li>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Branch Code: 210835
            </li>
            <li style={{color:'#3b3b3b',fontSize:13}}>
                Swift Code: FIRNZAJJ
            </li>
     </ul>
    <VerticalForm
    onSubmit={handleSubmit}
    resolver={schemaResolver}

    >
        {/* <FormInput
        type="select"
        name="currency"
        label="Select Currency"
        containerClass='mb-3'
        >
        { country === "South Africa" && <option value="ZAR">
            ZAR
        </option>}
        {country !== "South Africa" &&<option value="USD">
            USD
        </option>}
        </FormInput> */}
        <FormInput
        type="number"
        label={`Enter Amount In ${props.suspenseAccount?.accountMainCurrency}`}
        containerClass='mb-3'
        placeholder='0.00'
        name="amount"
        onChange={(x)=>{
            setState({...state,amount:x.target.value});
        }}
        />
        <FormInput
        type="textarea"
        label="Comment"
        containerClass='mb-3'
        name="clientComment"
        />
        <label>Proof Of Payment</label>
        <FileUploader
        onFileUpload={(x)=>setState({...state,files:x})}
        title="Upload Supporting Documents"
        />

        {/* <p>This Transaction will incure a charge of <strong> {props.accountMainCurrency} { (props?.product?.depositFeeEFT||0)?.toFixed(2) } </strong></p> */}
        <Button 
        type="submit"
        style={{ marginBottom: 10, backgroundColor: '#47878A', fontSize: 13, border: 'none' ,color:'#fff'}}
        disabled={state.loading}
        >
        {
            state.loading ? <Spinner></Spinner> : 'Pay Now'
        }   
        </Button>
     </VerticalForm>
    </>

  )
}

export default DepositToAccount