import React, { useState } from 'react'
import { Alert, Button, Row, Col } from 'react-bootstrap'
import { getCurrency, getDecodedUser, getEntityCountry, getGlobalSettings, getLoggedInUser } from '../../../../api/user/Cookie'
import ProductsService from '../../../../api/user/ProductsService'
import { FormInput, VerticalForm } from '../../../../components'
import FileUploader from '../../../../components/FileUploader'
import Spinner from '../../../../components/Spinner'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { calculateCharge, transactionId } from '../../../../CoolHelpers/CoolHelpers'
import { currencyFixer } from '../Product/Utils'

const GoToPaymentPlatform = (props) => {
    const [state, setState] = useState({});
    const user = getDecodedUser();
    const country = getEntityCountry();
    const global = getGlobalSettings();
    const { t } = useTranslation();

    const handleSubmit = (x) => {

        setState({ ...state, loading: true });

        var formData = new FormData();
        for (var key in x) {
            formData.append(key, x[key]);
        };

        ProductsService.makePayment(formData);
    };

    const schemaResolver = yupResolver(
        yup.object().shape({
            amount: yup.string().required(t('Please enter amount')),
        })
    );

    //    const totalCharges =()=> { return parseFloat(calculateCharge(state.amount,props?.product?.depositFeeCREDITCARDP)) + props?.product?.depositFeeCREDITCARDF||0?.toFixed(2)}

    return (
        <>
            {!props.displayBack && <Button
                onClick={props.back}
                style={{ marginBottom: 20, backgroundColor: '#F1F5F9', fontSize: 13, border: 'none' ,color:'#000'}}
            >
                Back
            </Button>}
            {
                state.error && <Alert>
                    {state.error}
                </Alert>
            }
            <VerticalForm
                onSubmit={handleSubmit}
                resolver={schemaResolver}
                formName="makeP"
            >
                <FormInput type="text" name="m1" value="5a0f07c7-1b10-40e2-b8c1-a2a23e674494" hidden />
                <FormInput type="text" name="m2" value="24ade73c-98cf-47b3-99be-cc7b867b3080" hidden />
                <FormInput type="text" name="p2" value={transactionId()} hidden />
                <FormInput type="text" name="p3" value={getDecodedUser().username + " deposit to suspense account"} hidden />
                <FormInput type="text" name="m4" value={user?.id} hidden /> { /** send user id as extra field */}
                <FormInput type="text" name="m5" value={props?.suspenseAccount?._id} hidden /> {/* send suspense account as extra field */}
                <FormInput type="text" name="m6" value={country} hidden /> {/* send send authentication token as another field */}
                <FormInput type="text" name="Budget" value="N" hidden />
                {/* <FormInput
        type="select"
        name="currency"
        label="Select Currency"
        containerClass='mb-3'
        >
        { country === "South Africa" && <option value="ZAR">
            ZAR
        </option>}
        {country !== "South Africa" &&<option value="USD">
            USD
        </option>}
        </FormInput> */}
                <FormInput
                    type="text"
                    containerClass='mb-3'
                    label="Enter Billing Email"
                    placeholder='enter your billing email here'
                    name="m9"
                />
                <FormInput
                    type="number"
                    label={`Enter Amount In ${props?.suspenseAccount?.accountMainCurrency}`}
                    containerClass='mb-3'
                    placeholder='0.00'
                    name={props?.suspenseAccount?.accountMainCurrency !== "ZAR" ? 'amount' : 'p4'}
                    value={state.amount}
                    onChange={(e) => {
                        setState({ ...state, amount: e.target.value });
                    }}
                />
                {props?.suspenseAccount?.accountMainCurrency !== "ZAR" && <FormInput
                    type="text"
                    label={`Converted Value in (ZAR)`}
                    containerClass='mb-3'
                    style={{ backgroundColor: '#F1F5F9', color: '#000',fontSize:12 }}
                    placeholder='0.00'
                    name="p4"
                    value={((state.amount||0) * global?.USDTOZARRATE)}
                />}

                {/* <p>This Transaction will incure a charge of <strong> {props.accountMainCurrency} { totalCharges() } </strong></p> */}

                {props?.suspenseAccount?.accountMainCurrency !== "ZAR" && <p style={{fontSize:12}}>Your payment will be converted to South African Rand for this payment proccess only at a rate of 1 USD to ZAR {global?.USDTOZARRATE}</p>}
                <Button
                    type="submit"
                    onClick={handleSubmit}
                    style={{ marginBottom: 10, backgroundColor: '#47878A', fontSize: 13, border: 'none' ,color:'#fff'}}
                    disabled={state.loading}
                >
                    {
                        state.loading ? <Spinner></Spinner> : 'Pay Now'
                    }

                </Button>
            </VerticalForm>
        </>

    )
}

export default GoToPaymentPlatform