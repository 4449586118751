import React, { useState } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap';
import ActivePlacements from './ActivePlacements';
import { DateHandler, currency } from '../../CoolHelpers/CoolHelpers';
import { currencyFixer } from '../dashboard/Analytics/Product/Utils';
import DeleteModal from './DeleteModal';

const ActiveSubscription = (props) => {
    const [showWalletDO, setShowWalletDO] = useState(false);
    const [show, setShow] = useState(false);

    const subscription = props.subscription;

    const nextDate = new Date().setDate(subscription.subscriptionDate);

    return (
        <Row style={{ marginTop: 30 }}>
            <ActivePlacements
                show={showWalletDO}
                onHide={() => setShowWalletDO(!showWalletDO)}
                {...subscription}
            />
            <DeleteModal
                show={show}
                onHide={() => setShow(!show)}
                {...subscription}

            />

            <Col lg="12">
                <Card style={{ cursor: 'pointer', backgroundColor: '#47878A', color: '#fff' }} >
                    <Card.Body>
                        <p style={{ color: '#fff', fontSize: 12, fontWeight: 600 }}>Automated Investment Subscription</p>
                        <p style={{ color: '#fff', fontSize: 12 }}>Debit Amount : {currency()} {currencyFixer(subscription.subscriptionTotalAmount)}</p>
                        <p style={{ color: '#fff', fontSize: 12 }}>Next Debit Date :   {DateHandler(nextDate)}</p>
                        {subscription.subscriptionMethod === "credit-card" && <p style={{ color: '#eee', fontSize: 12 }}>
                            To activate or update a card subscription, delete the current active one
                        </p>}
                        <Button
                            onClick={() => setShow(true)}
                            style={{ backgroundColor: '#F1F5F9', color: '#000', fontSize: 11, border: 'none', marginRight: 10 }}>
                            Delete
                        </Button>
                       { subscription.subscriptionMethod === "debit-order" && <Button
                            onClick={() => setShowWalletDO(true)}
                            style={{ backgroundColor: '#F1F5F9', color: '#000', fontSize: 11, border: 'none', }}>
                            Edit
                        </Button>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ActiveSubscription;