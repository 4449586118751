import {getEntityCountry, getGlobalSettings} from "../../../../api/user/Cookie";

export const currencyFixer = (num)=>{        
    
        return  num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

};


export const netContributions = (balance, intrestBalance, currency) => {
        const netContributions = ((balance + intrestBalance));

        return netContributions < '0.00' ? 0 :  currency + " " + currencyFixer(netContributions);
}

export const netTotal = (balance, pendingOut, currency) => {
    const netTotal = ((balance -( pendingOut || 0)));

    return netTotal < '0.00' ? 0 :  currency + " " + currencyFixer(netTotal);
}

export const interestEarned = (interest,currency) =>{
    return currency + " " + currencyFixer(interest||0)
}

export const pendingIn = (pendingIn,currency) =>{
    return currency + " " + currencyFixer(pendingIn||0);
}

export const pendingOut = (pendingOut,currency) =>{
    return currency + " " + currencyFixer(pendingOut  ||0);
}

