import React, {useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import Spinner from '../../../../../components/Spinner';
import { currencyFixer } from '../../Product/Utils';
import { getEntityCountry } from '../../../../../api/user/Cookie';

const CompleteDO = (props) => {
  
    useEffect(()=>{
        const starter = setTimeout(()=>{
            window.location.reload();
        },3000)

        return ()=> clearTimeout(starter);
    },[]);

    const currency = getEntityCountry() === "South Africa" ? "ZAR" : "USD"; 
    
  return (
    <Row>
        <Col lg="3">
        </Col>
        <Col lg="6">
           <h2>All set up !</h2>
           <p>Your account will be automatically funded with {currency} {currencyFixer(props.amount)}.
           </p>
           <p>Once your account has been credited, you'll be notified on email.
           </p>
        </Col>
        <Col lg="3">
        </Col>
    </Row>
  )
}

export default CompleteDO;