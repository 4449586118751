import {parseMarker} from '@fullcalendar/react';
import ApiClient from '../Client';
import {getDecodedUser, getLoggedInUser} from './Cookie';

const usertoken = getLoggedInUser()?.token;

const params = {
    headers:{
        'x-access-token':usertoken,
    }
};

const version = `/api/v1`;

const user = getDecodedUser();
class UserService {
    registerUser =(postData)=> ApiClient().post('/api/v1/users/register',postData);
   
    verifyUser = (token) => ApiClient().post(`/api/v1/users/verify/${token}`);

    getUserName =(x)=>ApiClient().post(`${version}/auth/resend`,x,params);

    loginUser =(postData) => ApiClient().post(`/api/v1/users/login`, postData);

    changeAccount =(postData) => ApiClient().post(`/api/v1/users/account/switch`, postData,params);

     //get users profile edit count
     getUsersCount=()=>ApiClient().get(`${version}/users/${usertoken}/count`);

     //request edit 
     requestEdit=()=>ApiClient().put(`${version}/users/requestupdate/${usertoken}`);

    completeRegistration =(token, postData)=> ApiClient().post(`/api/v1/users/register/success/${token}`, postData,params);

    getLoggedInUser=()=>ApiClient().get(`${version}/users/me`,params);

    editLoggedInUser=(x,id)=>ApiClient().put(`${version}/users/${id}/update`,x,params);

    createNewBeneficiary=(x)=>ApiClient().post(`${version}/beneficiaries/add`,x,params);

    editBeneficiary=(x,id)=>ApiClient().post(`${version}/beneficiaries/${id}`,x,params);

    getBeneficiaries=(x)=>ApiClient().get(`${version}/beneficiaries`,params);

    deletionRequest =(x)=>ApiClient().post(`${version}/users/deletionrequest`,x,params);

    getUserDeleteRequest =()=>ApiClient().get(`${version}/users/deletionrequest/my`,params);

    revokeDeleteRequest = () =>ApiClient().get(`${version}/users/deletionrequest/revoke`,params);

    forgotPasswordStarter = (identity) => ApiClient().get(`${version}/users/${identity}/forgot/password`,params);

    forgotPasswordFinisher = (token,data) => ApiClient().post(`${version}/users/forgot/password/${token}`, data,params);


    getUserSuspenseAccount = (country) => ApiClient().get(`${version}/accounts/my/suspense/${country}`,params);

    initiateInvestment = (data) =>  ApiClient().post(`${version}/properties/investment/new`,data , params);
  
    userInvestment =(propertyId)=> ApiClient().get(`${version}/properties/investment/user/${propertyId}`, params);

    fetchBankingDetails = () => ApiClient().get(`${version}/users/banking/${user.id}/all`,params);

     Me = () => ApiClient().get(`${version}/users/me`, params);


    // getUserDeleteRequest
}

export default new UserService();