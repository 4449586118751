import React, { useEffect, useState } from 'react';
import ProductsService from '../../api/user/ProductsService';
import SingleEntity from './SingleEntity';

const InvestmentEntities = () => {

    const [state, setState] = useState({ loading: true });

    useEffect(() => {
        ProductsService.getAllEntities().then((x) => {
            setState({
                loading: false, entities
                    : x.data.entities.filter((x) => {
                        return (x.entityType === "savings" || x.entityType === "equity") && x.entityAccess !== "deactive" 
                    }),
                accounts: x.data.clientActiveAccounts
            })
        });

    }, []);


    return (
        <>
            {(state.entities || []).map((z, index) => {
                
                const account = state.accounts.filter((x)=>{
                    return x.entity === z._id;
                });

                const realAccount = account.length >= 1 ? account[0] : {}

                return <SingleEntity {...z} index={index} account={realAccount} />
            })
            }
        </>

    )
}

export default InvestmentEntities;