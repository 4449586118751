import React, { useState } from 'react'
import WalletPlacement from '../dashboard/Analytics/Overview/WalletDO/WalletPlacement'
import { VerticalForm } from '../../components'
import EditBankDO from './EditBankDO'
import { Col, Row } from 'react-bootstrap'

const ActivePlacements = (props) => {
  return (
    <WalletPlacement {...props} >
      <Row>
        <Col lg="3">
        </Col>
        <Col lg="6">
          <EditBankDO {...props} />
        </Col>
        <Col lg="3">
        </Col>
      </Row>
    </WalletPlacement>
  )
}

export default ActivePlacements