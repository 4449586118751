import React, { useState } from 'react'
import { Card, Col, Row, Modal, Button, Alert } from 'react-bootstrap'
import DepositToAccount from './DepositToAccount';
import GoToPaymentPlatform from './GoToPaymentPlatform';
import FeatherIcon from 'feather-icons-react';
import PayNowZimbabwe from './PayNowZimbabwe';
import { getEntityCountry } from '../../../../api/user/Cookie';
import WalletDO from './WalletDO/WalletDO';
import { Wizard } from 'react-albus';
import CardDO from './WalletDO/CardDO';

const PaymentSelectionModal = (props) => {

    const [section, setSection] = useState();
    const country = getEntityCountry();
    const [showWalletDO, setShowWalletDO] = useState(false);
    const [showCardDO, setShowCardDO] = useState(false);

    return (
        <div>
            {!section && country === 'South Africa' && <Row>
                <Col lg="6">
                    <Card style={{ color: '#fff', cursor: 'pointer', backgroundColor: '#47878A' }} className="paymentSelectora" onClick={() => { setSection("paynow") }}>
                        <Card.Body>
                            {
                                country === "South Africa" && <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
                                        <div>
                                            <h4 style={{ margin: 0, fontSize: 12, color: '#fff' }}>Make an instant payment</h4>
                                            <p style={{ margin: 0, fontSize: 12 }}>Approved instantly</p>
                                        </div>
                                        <FeatherIcon icon="credit-card" color="#fff" />
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="6">
                    <Card style={{ color: '#fff', cursor: 'pointer', backgroundColor: '#47878A' }} className="paymentSelector" onClick={() => { setSection("banktransfer") }}>
                        <Card.Body>
                            {
                                country === "South Africa" && <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
                                        <div>
                                            <h4 style={{ margin: 0, fontSize: 12, color: '#fff' }}>Make a bank transfer</h4>
                                            <p style={{ margin: 0, fontSize: 12 }}>Requires approval</p>
                                        </div>
                                        <FeatherIcon icon="credit-card" color="#fff" />
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>}
            {!section && country === 'Zimbabwe' && <Row>
                <Col lg="6">
                    <Card style={{ color: '#fff', cursor: 'pointer', backgroundColor: '#47878A' }} className="paymentSelectora" onClick={() => { setSection("paynow") }}>
                        <Card.Body>
                            <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
                                        <div>
                                            <h4 style={{ margin: 0, fontSize: 12, color: '#fff' }}>Make an instant payment</h4>
                                            <p style={{ margin: 0, fontSize: 12 }}>Approved instantly</p>
                                        </div>
                                        <FeatherIcon icon="credit-card" color="#fff" />
                                    </Col>
                                </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>}

            <WalletDO
                show={showWalletDO}
                onHide={() => setShowWalletDO(!showWalletDO)}
            />
            <CardDO
                show={showCardDO}
                onHide={() => setShowCardDO(!showCardDO)}
            />


            {/* {
                !section && country !== "Zimbabwe" && <Row>
                    <Col lg="6">
                        <Card style={{ cursor: 'pointer', backgroundColor: '#47878A' }} onClick={() => setShowCardDO(true)}>
                            <Card.Body>
                                {
                                    country === "South Africa" && <Row>
                                        <Col style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
                                            <div>
                                                <h4 style={{ margin: 0 }}>Subscription</h4>
                                                <p style={{ margin: 0 }}>Automate monthly wallet funding</p>
                                            </div>
                                            <FeatherIcon icon="credit-card" />
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card style={{ cursor: 'pointer', backgroundColor: '#47878A' }} onClick={() => setShowWalletDO(true)}>
                            <Card.Body>
                                {
                                    country === "South Africa" && <Row>
                                        <Col style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
                                            <div>
                                                <h4 style={{ margin: 0, fontSize: 12, color: '#fff' }}>Debit Order</h4>
                                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>Automate mothly wallet funding</p>
                                            </div>
                                            <FeatherIcon icon="credit-card" />
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            } */}
            {
                section === "paynow" && country !== "Zimbabwe" && <GoToPaymentPlatform {...props} back={() => setSection()} suspsenseAccount={props.suspsenseAccount} />
            }
            {
                section === "paynow" && country === "Zimbabwe" && <GoToPaymentPlatform {...props} back={() => setSection()} suspsenseAccount={props.suspsenseAccount} />
            }
            {
                section === "banktransfer" &&
                <DepositToAccount
                    isSecondary={props.isSecondary}
                    permissions={props.permissions}
                    mainAccount={props.mainAccount} {...props} back={() => setSection()} />
            }
        </div>
    )
}

export default PaymentSelectionModal