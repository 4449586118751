import React, { useState } from 'react'
import { Alert, Button, Row, Col } from 'react-bootstrap'
import { getCurrency, getDecodedUser, getEntityCountry } from '../../api/user/Cookie'
import ProductsService from '../../api/user/ProductsService'
import { FormInput, VerticalForm } from '../../components'
import Spinner from '../../components/Spinner'

const BuyEquity = (props) => {
    const [state, setState] = useState({});

    const handleSubmit = (x) => {
        setState({...state,loading:true});
        x.amount = parseInt(x.shares)*parseInt(props?.entity?.sharePrice);
        x.sharePrice = props?.entity?.sharePrice;
        x.currency = getCurrency();

        if (props?._id) {
            const query = ProductsService.buySomeEquity(x, props._id);

            query.then((x) => {
                setState({ loading: false, error: 'Request Successfully Submitted' });
                document.location.reload();
            });

            query.catch((x) => {
                setState({ loading: false, error: x });
            });

        }
    };

    return (
        <>
            {
                state.error && <Alert variant='warning' color="#000">
                    {state.error.replace(/"/g,"")}
                </Alert>
            }
            <VerticalForm
                onSubmit={handleSubmit}
            >
                <FormInput
                    type="number"
                    label="Shares To Buy"
                    containerClass='mb-3'
                    placeholder='0.00'
                    name="shares"
                />
                <Button
                    type="submit"
                    style={{
                        backgroundColor: '#47878A',
                        border: 'none',
                        width: '100%',
                        color: '#fff',
                        fontSize: 13
                    }}
                    disabled={state.loading}
                >

                    {
                        state.loading ? <Spinner></Spinner> : 'Buy Equity'
                    }

                </Button>
            </VerticalForm>
        </>

    )
}

export default BuyEquity