import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { getEntityCountry } from '../../../api/user/Cookie';
import ProductsService from '../../../api/user/ProductsService';

export const generateLastDates = () => {
    // Get the current date
    const today = new Date();

    const firstDate = new Date();

    // Format the date as 'DD MM YYYY'
    const formattedDate = firstDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    // Create an array to store the last date of each month for the last 12 months
    const lastDates = [[formattedDate, 0]];

    // Loop through the last 12 months
    for (let i = 0; i < 11; i++) {
        // Create a new Date object for the last day of the current month
        const lastDate = new Date(today.getFullYear(), today.getMonth() - i, 0);

        // Format the date as 'DD MM YYYY'
        const formattedDate = lastDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        // Add the formatted date to the array
        lastDates.push([formattedDate, 0]);

    }
    return lastDates.reverse();

};

export const generateLastWeeks = () => {
    // Get the current date
    const today = new Date();

    // Create an array to store the last date of each week for the last 12 weeks
    const lastDates = [];

    // Loop through the last 12 weeks
    for (let i = 0; i < 12; i++) {
        // Create a new Date object for the last day of the current week
        const lastDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (i * 7));

        // Format the date as 'DD MM YYYY'
        const formattedDate = lastDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        // Add the formatted date to the array
        lastDates.push([formattedDate, 0]);
    }

    // Reverse the array so that the most recent date is at the beginning
    return lastDates.reverse();

};

export const generateLastDays = () => {
    // Get the current date
    const today = new Date();

    // Create an array to store the last 12 days
    const lastDates = [];

    // Loop through the last 12 days
    for (let i = 0; i < 12; i++) {
        // Create a new Date object for the last day
        const lastDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);

        // Format the date as 'DD MM YYYY'
        const formattedDate = lastDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        // Add the formatted date to the array
        lastDates.push([formattedDate, 0]);
    }

    lastDates.reverse();

    // Log the array to the console
    return lastDates;


};

const lastYear = generateLastDates();
const lastWeeks = generateLastWeeks();
const lastdays = generateLastDays();


const InvestmentsChart = () => {


    const [state, setState] = useState({
        selected: 'y12',
        data: lastYear,
        country: getEntityCountry()
    });

    const options = {
        title: {
            text: 'TOTAL PORTFOLIO VALUE',
            textStyle: {
                fontSize: 12,
                fontFamily: 'Montserrat'
            },
            left: "center",
        },
        showLoading: true,
        legend: { top: 30 },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            // data: state.xdata
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: state.data,
                type: 'line',
                color: '#81D0B9'
            }
        ]
    };

    useEffect(() => {

        setState({ ...state, loading: true, country: getEntityCountry() });

        ProductsService.dashboardTotals(state).then((x) => {
            setState({ selected:state.selected, loading: false, data: x.data.graphData });
        });

    }, [state.selected]);

    return (
        <Card>
            <Card.Body >
                <Row>
                    <Col lg="10">
                        {/* <div lg="4" style={{ display: 'flex' }}>
                            <div style={{ marginRight: 20 }}>
                                <p style={{ fontSize: 11, margin: 0 }}>ROI</p>
                                <p style={{ fontSize: 11, marginTop: 0, fontFamily: 'Montserrat', fontWeight: 600, color: 'green' }}>
                                    {getEntityCountry() === "Zimbabwe" ? "USD" : "ZAR"} 23.00
                                </p>
                            </div>

                            <div>
                                <p style={{ fontSize: 11, margin: 0 }}>ROI %</p>
                                <p style={{ fontSize: 11, marginTop: 0, fontFamily: 'Montserrat', fontWeight: 600, color: 'red' }}>
                                    {getEntityCountry() === "Zimbabwe" ? "USD" : "ZAR"} 30%
                                </p>
                            </div>
                        </div> */}
                    </Col>
                    <Col>
                        <div style={{ display: 'flex' }} >
                            <section onClick={() => {
                                setState({ ...state, selected: 'd12', data: lastdays })
                            }} className={`chat-buttons ${state.selected === "d12" ? "d12" : ""}`}>12D</section>
                            <section onClick={() => {
                                setState({ ...state, selected: 'w12', data: lastWeeks })
                            }} className={`chat-buttons ${state.selected === "w12" ? "w12" : ""}`} >12W</section>
                            <section onClick={() => {
                                setState({ ...state, selected: 'y12', data: lastYear })
                            }} className={`chat-buttons ${state.selected === "y12" ? "y12" : ""}`}>1Y</section>
                        </div>
                    </Col>
                </Row>
                <ReactECharts showLoading={state.loading} style={{ height: "350px", width: '100%' }} option={options} />
            </Card.Body>
        </Card>
    )
}

export default InvestmentsChart;