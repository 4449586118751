import React, { useState } from 'react'
import { FormInput, VerticalForm } from '../../components';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import Spinner from '../../components/Spinner';
import ProductsService from '../../api/user/ProductsService';

const EditBankDO = (props) => {
    const [state, setState] = useState({ ...props });

    const submitEdit = () => {
        setState({...state, laoding:true});
        ProductsService.updateDebitOrder(props._id ,state).then((x)=>{
            document.location.reload();
        }).catch((x)=>{
            setState({...state,error:x,loading:false})
        })
    };

    return (
        <>
            {
                state.error && <Alert>
                    {state.error}
                </Alert>
            }
            <FormInput
                type='number'
                containerClass='mb-3'
                defaultValue={state?.subscriptionTotalAmount}
                label="Amount"
                onChange={(x) => {
                    setState({ ...state, subscriptionTotalAmount: x.target.value });
                }}
            />
            <FormInput
                type='select'
                containerClass='mb-3'
                label="Subscription Order Date (of each month)"
                defaultValue={state.subscriptionDate}
                name="subscriptionDate"
                onChange={(x) => {
                    setState({ ...state, subscriptionDate: x.target.value });
                }}
            >
                <option value="">Select Subscription Date</option>
                <option value="1">1st</option>
                <option value="2">2nd</option>
                <option value="5">5th</option>
                <option value="7">7th</option>
                <option value="15">15th</option>
                <option value="25">25th</option>
                <option value="26">26th</option>
                <option value="30">30th</option>
                <option value="31">31th</option>
            </FormInput>
            <FormInput
                type='text'
                containerClass='mb-3'
                label="Account Holder Name"
                defaultValue={state?.bankAccountName}
                onChange={(x) => {
                    setState({ ...state, accountName: x.target.value });
                }}
            />
            <FormInput
                type='text'
                containerClass='mb-3'
                label="Contact Number"
                defaultValue={state.subscriptionContactPhone}
                onChange={(x) => {
                    setState({ ...state, subscriptionContactPhone: x.target.value });
                }}
            />
            <FormInput
                type='text'
                containerClass='mb-3'
                label="Address"
                defaultValue={state.subscriptionAddress}
                onChange={(x) => {
                    setState({ ...state, subscriptionAddress: x.target.value })
                }}
            />
            <FormInput
                type='text'
                containerClass='mb-3'
                label="Name of Bank"
                defaultValue={state?.subscriptionBankName}
                onChange={(x) => {
                    setState({ ...state, subscriptionBankName: x.target.value });
                }}
            />
            <FormInput
                type='text'
                containerClass='mb-3'
                label="Branch Name and Town"
                defaultValue={state?.subscriptionBankBranchName}
                onChange={(x) => {
                    setState({ ...state, subscriptionBankBranchName: x.target.value });
                }}
            />
            <FormInput
                type='text'
                containerClass='mb-3'
                label="Branch Number"
                name="branchNumber"
                defaultValue={state.subscriptionBankBranchNumber}
                onChange={(x) => {
                    setState({ ...state, subscriptionBankBranchNumber: x.target.value });
                }}
            />
            <FormInput
                type='text'
                containerClass='mb-3'
                label="Account Number"
                defaultValue={state?.subscriptionBankAccountNumber}
                onChange={(x) => {
                    setState({
                        ...state, subscriptionBankAccountNumber: x.target.value
                    });
                }}
            />
            <FormInput
                type="select"
                label="Type Of Account"
                containerClass='mb-3'
                defaultValue={state.subscriptionBankType}
                onChange={(x) => {
                    setState({ ...state, subscriptionBankType: x.target.value });
                }}
            >
                <option value="">Select account type</option>
                <option value="savings">Savings</option>
                <option value="current">Current (cheque)</option>
            </FormInput>
            <div>
                <Button onClick={submitEdit} className='btm-sm'>
                    {
                        state.loading ? <Spinner></Spinner> : 'Update Debit Order'
                    }
                </Button>
            </div>
        </>
    )
}

export default EditBankDO;