import { APICore } from './apiCore';

const api = new APICore();
const version = '/api/v1';

// account
function login(params: { email: string; password: string }) {
    const baseUrl = version+'/login/';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = version+'/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; phone: string }) {
    const baseUrl = version+'/users/register';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = version+'/forget-password/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };
