import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import ProductsService from '../../../../../api/user/ProductsService';
import Spinner from '../../../../../components/Spinner';
import { FormInput, VerticalForm } from '../../../../../components';
import UserService from '../../../../../api/user/UserService';

const CardDOSetup = (props) => {

    const [state, setState] = useState({ loading: true, ...props });
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [debitOrder, setDebitOrder] = useState("");
    const componentRef = React.useRef(null);
    const [userID, setUserId] = useState("");


    useEffect(() => {
        UserService.fetchBankingDetails()
            .then((x) => {
                //debit order reference id
                setState({ ...state, ...x.data?.bank, loading: false });
            });

        UserService.Me()
            .then((x) => {
                const addre = x.data.user?.address;
                const addressValue = addre?.address || "" + " " + addre?.appartmentSuite || "" + " " + addre?.city || "" + " " + addre?.country || "" + " " + addre?.state || "";
                setAddress(addressValue);
                setPhone(x.data.user.phone);
                setUserId(x.data.user?._id)
                let debitOrderId = (Math.floor(Math.random() * 90000000) + 10000000);
                setDebitOrder(debitOrderId);
            }).catch((x) => {
                console.log(x);
            });
    }, []);

    const handleSubmit = () => {
        setState({ ...state, loadingForm: true, error: null });
        const newSubscription = {
            user: userID,
            subscriptionType: props.type,
            subscriptionReference: debitOrder,
            subscriptionMethod: "credit-card",
            subscriptionFirstPaymentDate: state.subscriptionFirstPaymentDate,
            subscriptionDate: state.subscriptionDate,
            bankAccountName: state.bankAccountName,
            subscriptionContactPhone: state.subscriptionContactPhone,
            subscriptionAddress: state.subscriptionAddress,
            subscriptionTotalAmount: props.recurring,
            creditCardType: "savings",
            account: props?._id
        };

        ProductsService.createTempSubs(newSubscription)
            .then((x) => {
                document.manageSub.method = "POST";
                document.manageSub.action = 'https://cde.netcash.co.za/Site/TokeniseCardExternal.aspx';
                document.manageSub.submit();
            })
            .catch((x) => {
                setState({ ...state, loadingForm: false, error: x })
            })

    };

    const minDate = () => {
        const date = new Date();
        const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        const min = date.getFullYear() + "-" + month + "-" + (date.getDate() + 3);
        return min;
    }

    const minDateValue = minDate()

    return (
        <div>
            <Row>
                <Col lg="3">
                    <Button onClick={props.prev}>
                        Back
                    </Button>
                </Col>
                {state.loading ? <Col lg="6">
                    <h3>Setting up your subscription</h3>
                    <Spinner></Spinner>
                </Col> : <Col lg="6">
                    {
                        state.error && <Alert variant="danger" className="my-2">
                            {state.error}
                        </Alert>
                    }
                    <VerticalForm
                        onSubmit={handleSubmit}
                        formName="manageSub"
                    >
                        <FormInput id="PciKey" name="PciKey" type="hidden" value="a1fd3a49-4666-4ef9-86aa-d217990e2247" />
                        <FormInput type="hidden" name="Extra1" value={debitOrder} />
                        <FormInput
                            type='date'
                            min={minDateValue}
                            containerClass='mb-3'
                            name="subscriptionFirstPaymentDate"
                            label="First Subscription Date"
                            max={31}
                            onChange={(x) => {
                                setState({ ...state, subscriptionFirstPaymentDate: x.target.value });
                            }}
                        />
                        <FormInput
                            type='select'
                            containerClass='mb-3'
                            label="Subscription Order Date (of each month)"
                            max={31}
                            name="subscriptionDate"
                            min={0}
                            onChange={(x) => {

                                setState({ ...state, subscriptionDate: x.target.value });
                            }}
                        >
                            <option value="">Select Subscription Date</option>
                            <option value="1">1st</option>
                            <option value="2">2nd</option>
                            <option value="5">5th</option>
                            <option value="7">7th</option>
                            <option value="15">15th</option>
                            <option value="25">25th</option>
                            <option value="26">26th</option>
                            <option value="30">30th</option>
                            <option value="31">31th</option>
                        </FormInput>
                        <FormInput
                            type='text'
                            containerClass='mb-3'
                            label="Credit Card holder name"
                            name="bankAccountName"
                            onChange={(x) => {
                                setState({ ...state, bankAccountName: x.target.value });
                            }}
                        />
                        {/* <FormInput
            type="select"
            label="Credit Card Type"
            containerClass='mb-3'
            name="creditCardType"
            onChange={(x)=>{
                setState({...state,creditCardType:x.target.value});
            }}
            >
                <option value="">Select account type</option>
                <option value="savings">Savings</option>
                <option value="current">Current (cheque)</option>
            </FormInput> */}
                        <FormInput
                            type='text'
                            containerClass='mb-3'
                            label="Contact Number"
                            name='subscriptionContactPhone'
                            onChange={(x) => {
                                setState({ ...state, subscriptionContactPhone: x.target.value })
                            }}
                        />
                        <FormInput
                            type='text'
                            containerClass='mb-3'
                            label="Address"
                            name="subscriptionAddress"
                            onChange={(x) => {
                                setState({ ...state, subscriptionAddress: x.target.value })
                            }}
                        />
                        <Button disabled={state.loadingForm} type="submit" >
                            {
                                state.loadingForm ? <Spinner></Spinner> : "Subcribe"
                            }
                        </Button>
                    </VerticalForm>
                </Col>}
                <Col lg="3">
                </Col>
            </Row>

        </div>
    )
}

export default CardDOSetup