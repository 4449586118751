import React, { useState } from 'react'
import WalletPlacement from './WalletPlacement'
import { Table, Row, Col, Button, ProgressBar } from 'react-bootstrap';
import { FormInput, VerticalForm } from '../../../../../components';
import { getEntityCountry } from '../../../../../api/user/Cookie';
import { currencyFixer } from '../../Product/Utils';
import { Step, Steps, Wizard } from 'react-albus';
import WalletDOSetup from './WalletDOSetup';
import CompleteDO from './CompleteDO';

const WalletDO = (props) => {

    const country = getEntityCountry();
    const [state, setState] = useState({});


    return (
        <WalletPlacement {...props} >
            <Wizard
                render={({ step, steps }) => (
                    <React.Fragment>
                        <ProgressBar
                            animated
                            striped
                            variant="success"
                            now={((steps.indexOf(step) + 1) / steps.length) * 100}
                            className="mb-3 progress-sm"
                        />
                        <Steps>
                            <Step
                                id="start-setup"
                                render={({ next }) => {
                                    return <Row>
                                        <Col lg="3"></Col>
                                        <Col lg="6">
                                            <h4>Bank Debit Order</h4>
                                            <p>
                                                This action will deposit funds into your {props.entity.entityName} account, on a monthly basis.
                                            </p>
                                            <VerticalForm>
                                                <FormInput
                                                    type='number'
                                                    placeholder='0.00'
                                                    label="Enter Monthly Amount"
                                                    containerClass='mb-3'
                                                    value={state.amount}
                                                    onChange={(e) => {
                                                        setState({ ...state, amount: parseFloat(e.target.value) });
                                                    }}
                                                />
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Wallet</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{country === "South Africa" ? "South Africa (ZAR)" : "Zimbabwe (USD)"} Wallet</td>
                                                            <td>ZAR {currencyFixer(state?.amount || 0)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <Button disabled={!state.amount} onClick={next} >
                                                    Proceed
                                                </Button>
                                            </VerticalForm>
                                        </Col>
                                        <Col lg="3"></Col>
                                    </Row>
                                }}
                            />
                            <Step
                                id="fund-wallet-c"
                                render={({ next, previous }) => {
                                    return <WalletDOSetup
                                        prev={previous}
                                        next={next}
                                        reccuring={state.amount}
                                        amount={state.amount}
                                        firstAmount={state.amount}
                                        {...props}
                                    />
                                }}
                            />
                            <Step
                                id="complete-do"
                                render={({ prev }) => {
                                    return <CompleteDO
                                        amount={state.amount}
                                        {...props}
                                    />
                                }}
                            />
                        </Steps>

                    </React.Fragment>
                )}
            />
        </WalletPlacement>
    )
}

export default WalletDO;