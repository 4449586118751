import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { getDecodedUser, isUserAuthenticated, setLoggedInUser } from '../api/user/Cookie';
import PaymentFailed from '../pages/payment/PaymentFailed';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { decoded, encoded } from './RouterHelpers';
import InvestmentManagement from '../pages/investmentmanagement/InvestmentManagement';


// landing
const Landing = React.lazy(() => import('../pages/landing/'));

// dashboard
const EcommerceDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce/'));
const investmentmanagementDashboard = React.lazy(() => import('../pages/dashboard/Analytics/index'));

// extra pages
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));

// -other
const Profile = React.lazy(() => import('../pages/other/Profile'));

const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));

const Marketplace = React.lazy(() => import('../pages/sharesmarketplace/Marketplace'));


const paymentfail = React.lazy(() => import('../pages/payment/PaymentFailed'));

const paymentsuccess = React.lazy(() => import('../pages/payment/PaymentSuccess'));

const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));

const Membership = React.lazy(()=>import('../pages/Memberships'))

const Investments = React.lazy(() => import('../pages/Investments/index'));

const SuccessFulSub = React.lazy(() => import('../pages/Subscriptions/SuccessfulSub'));

const FailedSubRoutes = React.lazy(() => import('../pages/Subscriptions/FailedSub'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

const user:any = isUserAuthenticated() && getDecodedUser();

// dashboards
const dashboardRoutes: RoutesProps = {
    path: '/dashboard',
    name: 'Dashboards',
    icon: 'airplay',
    header: 'Navigation',
    children: [
        {
            path: '/dashboard/home',
            name: 'Home',
            component: EcommerceDashboard,
            route: PrivateRoute,
            roles: [user.isMainAccount],
        },
        {
            path: '/dashboard/investment-management',
            name: 'investment-management',
            component: investmentmanagementDashboard,
            route: PrivateRoute,
        },
        // {
        //     path: '/dashboard/investments',
        //     name: 'investments',
        //     component: Investments,
        //     route: PrivateRoute,
        // },
    ],
};

// const PaymentSuccessRoute: RoutesProps = {
//     path: '/payment/success',
//     name: 'Chat',
//     route: PrivateRoute,
//     icon: 'message-square',
//     component: paymentsuccess,
// };

// const PaymentFailedRoute: RoutesProps = {
//     path: '/payment/failed',
//     name: 'Chat',
//     route: PrivateRoute,
//     icon: 'message-square',
//     component: paymentfail
// };

const profileRoute: RoutesProps = {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    route: PrivateRoute,
    roles: [user.isMainAccount],
};

const InvestmentManagementRoute = {
    path: '/investments',
    name: 'Investments',
    component: InvestmentManagement,
    route: PrivateRoute,
    roles: [user.isMainAccount],

}

const MembershipRoute:RoutesProps = {
    path: '/memberships',
    name: 'Membership',
    component: Membership,
    route: PrivateRoute,
};

const SubRoutes:RoutesProps = {
    path: '/subcription-success',
    name: 'SubSuccess',
    component: SuccessFulSub,
    route: PrivateRoute,
};


const SubRoutesFailed:RoutesProps = {
    path: '/subcription-failed',
    name: 'SubSuccess',
    component: FailedSubRoutes,
    route: PrivateRoute,
};



const MarketplaceRoute: RoutesProps ={
    path: '/marketplace/shares',
    name: 'Marketplace',
    component: Marketplace,
    route: PrivateRoute,
}


const appRoutes = [profileRoute];

// auth
const authRoutes: RoutesProps[] = [
    // {
    //     path: '/auth/login',
    //     name: 'Login',
    //     component: Login,
    //     route: Route,
    // },
    // {
    //     path: '/auth/register',
    //     name: 'Register',
    //     component: Register,
    //     route: Route,
    // },
    // {
    //     path: '/auth/confirm',
    //     name: 'Confirm',
    //     component: Confirm,
    //     route: Route,
    // },
    // {
    //     path: '/auth/password/success',
    //     name: 'Confirm',
    //     component: ResetPassword,
    //     route: Route,
    // },
    // {
    //     path: '/auth/success',
    //     name: 'Confirm Success',
    //     component: ConfirmSuccess,
    //     route: Route,
    // },
    // {
    //     path: '/auth/forget-password',
    //     name: 'Forget Password',
    //     component: ForgetPassword,
    //     route: Route,
    // },
    // {
    //     path: '/auth/lock-screen',
    //     name: 'Lock Screen',
    //     component: LockScreen,
    //     route: Route,
    // },
    // {
    //     path: '/redirect/auth',
    //     name: 'Logout',
    //     component: Logout,
    //     route: Route,
    // },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
    {
        path: '/landing',
        name: 'landing',
        component: Landing,
        route: Route,
    },
    {
        path: '/redirect/auth',
        name: 'Redirect',
        component: ()=>{
            setLoggedInUser(null);
            window.location.href = window.location.origin.includes("tsigiro.com") ? process.env.REACT_APP_AUTH + "?" + encoded(window.location.href.replace("/redirect/auth","")) || '' : process.env.REACT_APP_DEV_AUTH + "?" + encoded(window.location.href.replace("/redirect/auth","")) || '';
            return null;
        },
        route: Route,
    },
    {
        path: '/redirect/logout',
        name: 'Redirect',
        component: ()=>{
            setLoggedInUser(null);
                       window.location.href = window.location.origin.includes("tsigiro.com") ? process.env.REACT_APP_AUTH + "?" + encoded("logout") || '' : process.env.REACT_APP_DEV_AUTH + "?" + encoded("logout") || '';

            return null;
        },
        route: Route,
    },
    {
        path: '/payment/success',
        name: 'Chat',
        route: Route,
        component: paymentsuccess,
    },
    {
       path: '/payment/failed',
       name: 'Chat',
       route: Route,
       component: paymentfail
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        route: Route,
    },
    {
        path: '/error-404',
        name: 'Error - 404',
        component: Error404,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: Error500,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes

const authProtectedRoutes = [rootRoute, dashboardRoutes, ...appRoutes ,MarketplaceRoute, MembershipRoute , SubRoutes, SubRoutesFailed, InvestmentManagementRoute];
const publicRoutes = [ ...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
