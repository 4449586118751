import React, { useEffect, useState } from 'react';
import ProductsService from '../../api/user/ProductsService';
import { Accordion, Col, Row, Button, Card, Badge, Pagination, Table } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { getCurrency, getEntityCountry } from '../../api/user/Cookie';
import { currencyFixer } from '../dashboard/Analytics/Product/Utils';
import { DateHandler, currency } from '../../CoolHelpers/CoolHelpers';
import { generateLastDates, generateLastWeeks, generateLastDays } from '../dashboard/Ecommerce/InvestmentsChart';
import ReactECharts from 'echarts-for-react';
import { FormInput, VerticalForm } from '../../components';
import SellEquity from './SellEquity';
import BuyEquity from './BuyEquity';
import RecurringInvestment from './RecurringInvestment';
import ActiveSubscription from './ActiveSubscription';
import Invest from './Invest';
import WithdrawFrom from './Withdraw';

const lastYear = generateLastDates();
const lastWeeks = generateLastWeeks();
const lastdays = generateLastDays();

const SingleEntity = (props) => {

    const [state, setState] = useState({
        loading: true,
        selected: 'y12',
        nav: 'w12',
        data: lastYear,
        country: getEntityCountry()
    });
    const [countP, setCountP] = useState([])
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(Infinity);
    const country = getEntityCountry();

    useEffect(() => {
        setState({ ...state, loading: true });
        ProductsService.getAccountChart(country, props?.account?._id, state, page).then((x) => {
            let pages = Math.floor(x.data.transactionCount / 5) === 0 ? 1 : Math.floor(x.data.transactionCount / 5) + 1;
            paginationHandler(pages);
            setState({
                ...state, loading: false,
                data: x.data.graphData,
                transactions: x.data.transactions,
                pendingIn: x.data.pendingIn,
                pendingOut: x.data.pendingOut

            });
        }).catch((x) => {
            setState({ ...state, loading: false });
        })
    }, [state.selected, props._id]);

    const options = {
        title: {
            text: 'ASSET ACTIVITY',
            textStyle: {
                fontSize: 13,
                fontFamily: 'Montserrat'
            },
            left: "center",
        },
        showLoading: true,
        legend: { top: 30 },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            // data: state.xdata
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: state.data,
                type: 'line',
                color: '#81D0B9'
            }
        ]
    };

    const calculateROI = () => {
        const totalAmount = (((props.account?.shares || 0) * (props?.sharePrice) || 0) - (props.account?.balance - (props.totalSoldAmount || 0))) || 0;
        const percentage = ((((totalAmount)) / (props.account.balance - (props?.totalSoldAmount || 0))) || 0) * 100;
        return {
            amount: currencyFixer(totalAmount),
            percentage: percentage
        };
    };

    const sortPagination = (paginationItems) => {
        const first5 = paginationItems.slice(0, 5);
        const last5 = paginationItems.slice(-5);

        const overlap = first5.filter((item) => last5.includes(item));
        const uniqueLast5 = last5.filter((item) => !overlap.includes(item));

        let sortedPagination = [...first5, <Pagination.Ellipsis key="ellipsis" />, ...uniqueLast5];
        if (page <= 3) {
            sortedPagination = paginationItems.slice(0, 5 + (page - 1));
        } else if (page >= paginationItems.length - 2) {
            sortedPagination = paginationItems.slice(-5);
        } else {
            sortedPagination = paginationItems.slice(page - 3, page + 2);
            sortedPagination = [paginationItems[0], <Pagination.Ellipsis key="ellipsis" />, ...sortedPagination, <Pagination.Ellipsis key="ellipsis2" />, paginationItems[paginationItems.length - 1]];
        }

        return sortedPagination;
    };


    const paginationHandler = (pages) => {
        let active = state.active;
        setPages(pages);
        let paginationItems = [];
        // let pages = [];
        for (let number = 1; number <= pages; number++) {
            paginationItems.push(
                <Pagination.Item key={number} active={number === active} onClick={(x) => setPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        setCountP(sortPagination(paginationItems));

    };

    const subscription = props.account.subscription?.length >= 1 ? props.account.subscription[0] : null;

    return (
        <Accordion.Item eventKey={props.index + 1} style={{ backgroundColor: 'transparent' }}>
            <Accordion.Button style={{ backgroundColor: 'transparent' }}  >
                <Row style={{ width: '100%', fontSize: 13, color: '#000', fontFamily: 'Montserrat' }}>
                    <Col lg="3">
                        <h3 style={{ fontSize: 18, fontWeight: 600, marginBottom: 20, marginTop: 20 }}>
                            {props.entityName}
                        </h3>
                    </Col>
                    {props.entityType === "equity" ? <Col lg="3">
                        <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                            SHARES
                        </p>
                        {state.loading ? <p>-</p> : <p style={{ fontWeight: 600 }}>{parseInt(props.account.shares) || 0}</p>}
                    </Col> :
                        <Col lg="3">
                            <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                                NET BALANCE
                            </p>
                            {state.loading ? <p>-</p> : <p style={{ fontWeight: 600 }}>{currency()} {currencyFixer((props.account?.balance || 0) + (props.account?.interestBalance || 0))}</p>}
                        </Col>

                    }
                    {
                        props.entityType === "equity" ? <Col lg="3">
                            <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                              SHARE PRICE
                            </p>
                            {state.loading ? <p>-</p> : <p style={{ fontWeight: 600 }}>{currency()} {currencyFixer(props.sharePrice)}</p>}
                        </Col> : <Col lg="3">
                            <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                              Annual Target Return
                            </p>
                            {state.loading ? <p>-</p> : <p style={{ fontWeight: 600 }}>{props.interestRatePerAnnum}%</p>}
                        </Col>
                    }
                    {props.entityType === "equity" ? < Col lg="3">
                        <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                            SHARES VALUE
                        </p>
                        {state.loading ? <p>-</p> : <p style={{ fontWeight: 600 }}>{currency()} {currencyFixer((props.sharePrice) * (props?.account?.shares || 0))}</p>}
                    </Col> : <Col lg="3">
                        <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                            CONTRIBUTIONS
                        </p>
                        {state.loading ? <p>-</p> : <p style={{ fontWeight: 600 }}>{currency()} {currencyFixer(props.account.balance || 0)}</p>}
                    </Col>}
                </Row>
            </Accordion.Button>
            <Accordion.Body>
                <Row>
                    <Col lg="7">
                        <div style={{ display: 'flex', width: 'auto' }} >
                            <section style={{ width: 'auto', fontSize: 13, width: state.nav === "w12" ? '100px' : 'auto' }} onClick={() => {
                                setState({ ...state, nav: 'w12' })
                            }} className={`chat-buttons ${state.nav === "w12" ? "w12" : ""}`} >Chart</section>
                            <section style={{ width: state.nav === "d12" ? '100px' : 'auto', fontSize: 13 }} onClick={() => {
                                setState({ ...state, nav: 'd12', })
                            }} className={`chat-buttons ${state.nav === "d12" ? "d12" : ""}`}>Transactions</section>

                        </div>
                    </Col>
                    {state.nav === "w12" ? <Col lg="2">
                        <div style={{ display: 'flex' }} >
                            <section onClick={() => {
                                setState({ ...state, selected: 'd12', data: lastdays })
                            }} className={`chat-buttons ${state.selected === "d12" ? "d12" : ""}`}>12D</section>
                            <section onClick={() => {
                                setState({ ...state, selected: 'w12', data: lastWeeks })
                            }} className={`chat-buttons ${state.selected === "w12" ? "w12" : ""}`} >12W</section>
                            <section onClick={() => {
                                setState({ ...state, selected: 'y12', data: lastYear })
                            }} className={`chat-buttons ${state.selected === "y12" ? "y12" : ""}`}>1Y</section>
                        </div>
                    </Col> : <Col lg="2"></Col>}
                    {props.entityType === "equity" ? <Col lg="3">
                        <div>
                            <Button
                                onClick={() => {
                                    setState({ ...state, nav: 'sell' });
                                }}
                                className="float-end"
                                style={{ backgroundColor: state.nav === "sell" ? '#47878A' : '#F1F5F9', color: state.nav === "sell" ? '#fff' : '#000', fontSize: 13, border: 'none', }}
                            >Sell</Button>
                            <Button
                                onClick={() => {
                                    setState({ ...state, nav: 'buy' });
                                }}
                                className="float-end"
                                style={{ backgroundColor: state.nav === "buy" ? '#47878A' : '#F1F5F9', color: state.nav === "buy" ? '#fff' : '#000', fontSize: 13, border: 'none', marginRight: 10 }}
                            >Buy</Button>
                        </div>
                    </Col> : <Col lg="3">
                        <div>
                            <Button
                                onClick={() => {
                                    setState({ ...state, nav: 'withdraw' })
                                }}
                                className="float-end"
                                style={{ backgroundColor: state.nav === "withdraw" ? '#47878A' : '#F1F5F9', color: state.nav === "withdraw" ? '#fff' : '#000', fontSize: 13, border: 'none', }}
                            >Withdraw</Button>
                            <Button
                                onClick={() => {
                                    setState({ ...state, nav: 'invest' })
                                }}
                                className="float-end"
                                style={{ backgroundColor: state.nav === "invest" ? '#47878A' : '#F1F5F9', color: state.nav === "invest" ? '#fff' : '#000', fontSize: 13, border: 'none', marginRight: 10 }}
                            >Invest</Button>
                        </div>

                    </Col>}
                </Row>
                <Row>
                    <Col lg="3" >
                        <Card style={{ marginTop: 30 }}>
                            <Card.Header>Summary</Card.Header>
                            {props.entityType === "equity" ? <Card.Body>
                                <Row>
                                    <Col>
                                        <p style={{ fontSize: 13 }}>Contributions</p>
                                    </Col>
                                    <Col>
                                        <p style={{ float: 'right', fontWeight: 600, fontSize: 13 }}>{currency()} {currencyFixer(props.account.balance)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p style={{ fontSize: 13 }}>ROI {calculateROI().percentage?.toFixed(2) || '0.00'} %</p>
                                    </Col>
                                    <Col>
                                        <Badge
                                            style={{
                                                backgroundColor: 'green',
                                                float: 'right',
                                                fontSize: 13,
                                                marginTop: -2,
                                                width: '50%',
                                                height: '23px', display: 'flex', alignItems: 'center', color: '#000', fontSize: '15px'
                                            }}
                                            bg={parseInt(calculateROI().percentage) > 0 ? "success" : parseInt(calculateROI().percentage) === 0 ? "warning" : "danger"}
                                        >
                                            {calculateROI().amount}
                                            <FeatherIcon
                                                icon={parseInt(calculateROI().percentage) > 0 ? "trending-up" : parseInt(calculateROI().percentage) === 0 ? "minus" : "trending-down"}
                                                style={{ marginLeft: 5 }}
                                            />
                                        </Badge>
                                    </Col>
                                </Row>
                                <hr></hr>
                                {getEntityCountry() === "South Africa" && <Button
                                    onClick={() => {
                                        setState({ ...state, nav: 'rec-eqity' })
                                    }}
                                    style={{ backgroundColor: state.nav === "rec-eqity" ? '#47878A' : '#F1F5F9', color: state.nav === "rec-eqity" ? '#fff' : '#000', fontSize: 13, border: 'none', }}
                                >
                                    Add Reccuring
                                </Button>}
                            </Card.Body> : <Card.Body>
                                <Row>
                                    <Col>
                                        <p style={{ fontSize: 13 }}>Interest Earned @{props.interestRatePerAnnum}% p/a</p>
                                    </Col>
                                    <Col>
                                        <p style={{ fontWeight: 600, fontSize: 13, float: 'right' }}>{currency()} {currencyFixer(props.account?.interestBalance || 0)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p style={{ fontSize: 13 }}>Pending In</p>
                                    </Col>
                                    <Col>
                                        <p style={{ fontWeight: 600, fontSize: 13, float: 'right' }}>{currency()} {currencyFixer(state.pendingIn || 0)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p style={{ fontSize: 13 }}>Pending Out</p>
                                    </Col>
                                    <Col>
                                        <p style={{ fontWeight: 600, fontSize: 13, float: 'right' }}>{currency()} {currencyFixer(state.pendingOut || 0)}</p>
                                    </Col>
                                </Row>
                                <hr></hr>
                                {getEntityCountry() === "South Africa" && <Button
                                    onClick={() => {
                                        setState({ ...state, nav: 'rec-eqity' })
                                    }}
                                    style={{ backgroundColor: '#F1F5F9', color: '#000', fontSize: 13, border: 'none', }}
                                >
                                    Add Reccuring
                                </Button>}
                            </Card.Body>}
                        </Card>
                    </Col>
                    <Col lg="9">
                        {
                            state.nav === "sell" && <Card style={{ marginTop: 30 }}>
                                <Card.Header>
                                    <p style={{ margin: 0, fontSize: 13, fontFamily: 'Montserrat' }}>Sell {props.entityName} Shares</p>
                                </Card.Header>
                                <Card.Body>
                                    <SellEquity
                                        {...props.account}
                                        entity={props}
                                        pendingIn={state.pendingIn || 0}
                                        pendingOut={state.pendingOut || 0}
                                    />
                                </Card.Body>
                            </Card>
                        }
                        {
                            state.nav === "buy" && <Card style={{ marginTop: 30 }}>
                                <Card.Header>
                                    <p style={{ margin: 0, fontSize: 13, fontFamily: 'Montserrat' }}>Buy {props.entityName} Shares</p>
                                </Card.Header>
                                <Card.Body>
                                    <BuyEquity {...props.account} entity={props} />
                                </Card.Body>
                            </Card>
                        }
                        {
                            state.nav === "rec-eqity" && <>

                                {!subscription ? <RecurringInvestment {...props.account} entity={props} /> : <ActiveSubscription subscription={subscription} />}

                            </>
                        }
                        {state.nav === "w12" && <Card style={{ marginTop: 30 }}>
                            <Card.Body>
                                <ReactECharts showLoading={state.loading} style={{ height: "350px", width: '100%' }} option={options} />
                            </Card.Body>
                        </Card>}
                        {state.nav === "d12" && <Card style={{ marginTop: 30 }} >
                            <Card.Body>
                                {props.entity?.entityType === "equity" ? <Table >
                                    <thead style={{ backgroundColor: '#F1F5F9', fontSize: 13, border: 'none' }}>
                                        <tr style={{ border: 'none', borderBottom: 'none' }}>
                                            <th scope="col">Reference #</th>
                                            <th scope="col">Created On</th>
                                            <th scope="col">Approved On</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Shares</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ height: '20px', overflow: 'scroll', fontSize: 13 }} >
                                        {(state.transactions || []).map((record, index) => {
                                            return (
                                                <tr key={index}
                                                    onClick={() => {
                                                        // setTransaction(record);
                                                        // setShowTransactionModal(true);
                                                    }}
                                                    style={{ cursor: 'pointer', width: 'auto' }}
                                                >
                                                    <th scope="row">{record.transactionId}</th>
                                                    <td>{DateHandler(record.dateCreated)}</td>
                                                    <td>{record?.dateApproved ? DateHandler(record?.dateApproved) : 'Pending'}</td>
                                                    <td><span style={{
                                                        backgroundColor: props.account?._id === record.to ? record.status === 'Pending' ? 'orange' : 'green' : record.status === 'Pending' ? 'orange' : 'red',
                                                        color: '#fff', padding: 3, borderRadius: 3,
                                                    }}>
                                                        {record.transactionType}</span></td>
                                                    <td>{record.currency === "Rand" ? "ZAR" : record.currency} {currencyFixer(record.amount)}</td>
                                                    <td>{record.status === "Rejected" ?
                                                        <span style={{
                                                            backgroundColor: 'brown',
                                                            color: '#fff', padding: 3, borderRadius: 3,
                                                        }}>
                                                            {record.status}</span> : record.status
                                                    }</td>
                                                    <td>{currencyFixer(record?.shares)}</td>
                                                    <td>{record.currency === "Rand" ? "ZAR" : record.currency} {currencyFixer(record.sharePrice)}</td>
                                                    <td>{props.account?._id === record.to ? 'Incoming' : 'Outgoing'}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table> : <Table>
                                    <thead style={{ backgroundColor: '#F1F5F9', fontSize: 13, border: 'none' }}>
                                        <tr style={{ border: 'none', borderBottom: 'none' }}>
                                            <th scope="col">#</th>
                                            <th scope="col">Created On</th>
                                            <th scope="col">Approved On</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ height: '20px', overflow: 'scroll', fontSize: 13 }}>
                                        {(state.transactions || []).map((record, index) => {
                                            return (
                                                <tr key={index}
                                                    onClick={() => {
                                                        // setTransaction(record);
                                                        // setShowTransactionModal(true);
                                                    }}
                                                    style={{ cursor: 'pointer', width: 'auto' }}
                                                >
                                                    <th scope="row">{record.transactionId}</th>
                                                    <td>{DateHandler(record.dateCreated)}</td>
                                                    <td>{record?.dateApproved ? DateHandler(record?.dateApproved) : 'Pending'}</td>
                                                    <td style={{ width: '150px' }}><span style={{
                                                        backgroundColor: props.account?._id === record.to ? record.status === 'Pending' ? 'orange' : 'green' : record.status === 'Pending' ? 'orange' : 'red',
                                                        color: '#fff', padding: 3, borderRadius: 3,
                                                    }}>
                                                        {record.transactionType}</span></td>
                                                    <td>{record.currency} {currencyFixer(record.amount)}</td>
                                                    <td>{record.status === "Rejected" ?
                                                        <span style={{
                                                            backgroundColor: 'brown',
                                                            color: '#fff', padding: 3, borderRadius: 3,
                                                        }}>
                                                            {record.status}</span> : record.status
                                                    }</td>
                                                    <td>{props.account?._id === record.to ? 'Incoming' : 'Outgoing'}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                                }
                            </Card.Body>
                        </Card>
                        }
                        {
                            state.nav === "invest" && <Card style={{ marginTop: 30 }}>
                                <Card.Header>Invest into {props.entityName}</Card.Header>
                                <Card.Body>
                                    <Invest  {...props.account} entity={props} />
                                </Card.Body>
                            </Card>
                        }
                        {
                            state.nav === "withdraw" && <Card style={{ marginTop: 30 }}>
                                <Card.Header>Withdraw from {props.entityName}</Card.Header>
                                <Card.Body>
                                    <WithdrawFrom  {...props.account} entity={props} />
                                </Card.Body>
                            </Card>
                        }
                    </Col>

                </Row>
            </Accordion.Body>
        </Accordion.Item >
    )
}

export default SingleEntity;