import React,{useState} from 'react';
import {  Offcanvas, Button } from 'react-bootstrap';
import { currency } from '../../../../../CoolHelpers/CoolHelpers';
import { currencyFixer } from '../../Product/Utils';

const WalletPlacement = (props) => {

  const [show, setShow] = useState(false);

    return (
            <Offcanvas style={{width:'100%',zIndex:11000}} show={props.show} onHide={props.onHide} placement="end">
                <Offcanvas.Header closeButton>
                    <div>
                    {/* <h4 id="offcanvasTopLabel" style={{fontSize:20,fontWeight:600}}> {currency()} {currencyFixer(props?.bucket?.minimumReccuringAmount)}</h4> */}
                    {/* <h6 id="offcanvasTopLabel">{props?.bucket?.name}</h6> */}
                    </div>
                    <p style={{fontSize:20,fontWeight:600}}>
                        Automated Investment
                    </p>
                </Offcanvas.Header>
                <Offcanvas.Body>
                {props.children}
                </Offcanvas.Body>
            </Offcanvas>
    );
};


export default WalletPlacement;
