import React, { useEffect } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

import {isUserAuthenticated,  getDecodedUser} from '../api/user/Cookie';

import { useIdleTimer } from 'react-idle-timer';
import { getGlobalSettings } from '../api/user/Cookie';

interface PrivateRouteProps {
    component: React.FunctionComponent<RouteProps>;
    roles?: string;
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */

const Timeout = getGlobalSettings()?.Timeout;

const PrivateRoute = ({ component: Component, roles, ...rest }: PrivateRouteProps) => {

    const [sessionIdle, setSessionIdle] = React.useState(false);
   
	const {
        start
      } = useIdleTimer({
        timeout: Timeout || 600000,
        onAction: () => { setSessionIdle(false);},
        debounce: 500,
        onIdle: () => {
             setSessionIdle(true);
             alert("You are idle system will now automatically log you out")
             window.location.href = "/#/redirect/logout";
             },
        stopOnIdle: true,
        startManually: true,
      })
      useEffect(() => {
        start();
    }, [])

    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps) => {
                if (isUserAuthenticated() === false) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{
                                pathname:'/redirect/auth',
                                state: { from: props['location'] },
                            }}
                        />
                    );
                    // return null
                }

                const loggedInUser:any = getDecodedUser();
                // check if route is restricted by role
                // if (roles && !loggedInUser?.isMainAccount) {
                //     // role not authorised so redirect to home page
                //     return <Redirect to={{ pathname: 'dashboard/home' }} />;
                // }
                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
