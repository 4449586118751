import React, { useState } from 'react'
import WalletPlacement from './WalletPlacement'
import { Steps, Wizard, Step } from 'react-albus'
import { ProgressBar } from 'react-bootstrap'
import { Table, Row, Col, Button } from 'react-bootstrap';
import CardDOSetup from './CardDOSetup';
import { currencyFixer } from '../../Product/Utils';
import { getEntityCountry } from '../../../../../api/user/Cookie';
import { FormInput, VerticalForm } from '../../../../../components';


const CardDO = (props) => {

    const [state, setState] = useState({});

    const country = getEntityCountry();

    return (
        <WalletPlacement {...props} >
            <Wizard
                render={({ step, steps }) => {
                    return <React.Fragment>
                        <ProgressBar
                            animated
                            striped
                            variant="success"
                            now={((steps.indexOf(step) + 1) / steps.length) * 100}
                            className="mb-3 progress-sm"
                        />
                        <Steps>
                            <Step
                                id="inital"
                                render={({ next }) => {
                                    return <Row>
                                        <Col lg="3"></Col>
                                        <Col lg="6">
                                            <h4>Card Subscription</h4>
                                            {
                                                props.type === "entity-funding" && <p>
                                                    This action will allow automated investments, into {props?.entity.entityName}.
                                                </p>
                                            }
                                            <VerticalForm>
                                                <FormInput
                                                    type='number'
                                                    placeholder='0.00'
                                                    label="Enter Monthly Amount"
                                                    containerClass='mb-3'
                                                    value={state.amount}
                                                    onChange={(e) => {
                                                        setState({ ...state, amount: parseFloat(e.target.value) });
                                                    }}
                                                />
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Wallet</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{country === "South Africa" ? "South Africa (ZAR)" : "Zimbabwe (USD)"} Wallet</td>
                                                            <td>ZAR {currencyFixer(state.amount || 0)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <Button disabled={!state.amount} onClick={next} >
                                                    Proceed
                                                </Button>
                                            </VerticalForm>
                                        </Col>
                                        <Col lg="3"></Col>
                                    </Row>
                                }}
                            />
                            <Step
                                id="card-complete"
                                render={({ next, previous }) => {
                                    return <CardDOSetup
                                        recurring={state.amount}
                                        amount={state.amount}
                                        {...props}
                                    />
                                }}
                            />
                        </Steps>
                    </React.Fragment>
                }}
            />
        </WalletPlacement>
    )
}

export default CardDO