import React, { useState } from 'react';
import { Alert, Button, Row, Col, Badge } from 'react-bootstrap';
import { getCurrency, getDecodedUser, getEntityCountry, getLoggedInUser } from '../../api/user/Cookie';
import ProductsService from '../../api/user/ProductsService';
import { FormInput, VerticalForm } from '../../components';
import FeatherIcon from 'feather-icons-react';
import Spinner from '../../components/Spinner';

const SellEquity = (props) => {

    const [state, setState] = useState({});
    const country = getEntityCountry()

    const handleSubmit = (x) => {
        setState({ loading: true });
        x.sharePrice = x.sellingPrice;
        x.amount = parseInt(x.shares) * x.sellingPrice;
        x.currency = getCurrency();

        if (props?._id) {
            if (((props?.shares ? props?.shares : 0) - parseInt(x.shares) - (props?.pendingOut ? props?.pendingOut : 0)) >= 0) {

                const query = ProductsService.sellSomeEquity(x, props._id, country);
                query.then((x) => {
                    setState({ loading: false, error: 'Request Successfully Submitted' });
                    document.location.reload();
                });

                query.catch((x) => {
                    setState({ loading: false, error: x });
                });
            } else {
                setState({ loading: false, error: 'Insufficient Shares Avalaible' })
            }

        }

    };

    return (
        <>
            {
                state.error && <Alert variant='warning'>
                    {state.error.replace(/"/g,"")}
                </Alert>
            }
            <VerticalForm
                onSubmit={handleSubmit}
            >
                <FormInput
                    type="select"
                    name="withdrawalTo"
                    label="Choose Where you would like to receive these funds to"
                    containerClass='mb-3'
                >
                    <option value="bank">
                        Withdraw To My Bank
                    </option>
                    <option value="suspense">
                        Withdraw To My Suspense Account
                    </option>
                </FormInput>
                <FormInput
                    type="number"
                    label="Number of Shares"
                    containerClass='mb-3'
                    placeholder='0.00'
                    name="shares"
                />
                <FormInput
                    type="number"
                    label="Enter Selling Price For A Single Share"
                    containerClass='mb-3'
                    placeholder='0.00'
                    name="sellingPrice"
                />
                <Button
                    type="submit"
                    style={{ marginTop: 30, 
                        backgroundColor: '#47878A', 
                        border: 'none', 
                        width: '100%',
                        color:'#fff',
                        fontSize:13
                    }}
                    disabled={state.loading}
                >
                    {
                        state.loading ? <Spinner></Spinner> : 'Sell Equity'
                    }

                </Button>
            </VerticalForm>
        </>

    )
}

export default SellEquity