import React from 'react';
import { Modal } from 'react-bootstrap';

const TransactionModal = (props:any) => {
  return (
   <Modal show={props.show} onHide={props.onHide} size="lg" centered>
                <Modal.Header onHide={props.onHide} closeButton>
                    <Modal.Title as="h5">Transaction Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {
                            props.comment || 'No comments'
                        }
                    </p>
                </Modal.Body>
   </Modal>
  )
}

export default TransactionModal;