import ApiClient from '../Client';
import { getDecodedUser, getEntityCountry, getLoggedInUser, isUserAuthenticated } from './Cookie';

const params = {
    headers: {
        'x-access-token': isUserAuthenticated() ? getLoggedInUser()?.token : undefined,
    }
};
const version = `/api/v1`;

const userId = isUserAuthenticated() ? getDecodedUser()?.id : undefined;

const country = getEntityCountry();

class UserService {

    getUserSuspenseAccount = (country, page, data) => ApiClient().post(`${version}/accounts/my/suspense/${country}/${page}`, data, params);

    getUserSavingsAccount = (country) => ApiClient().get(`${version}/accounts/my/savings/${country}`, params);

    getUserEquityAccount = (country) => ApiClient().get(`${version}/accounts/my/equity/${country}`, params);

    getAllProducts = () => ApiClient().get(`${version}/products`, params);

    getAllEntities = () => ApiClient().get(`${version}/entities/clients/${country}`, params);

    getInvestmentsAccounts = (x) => ApiClient().get(`${version}/accounts/my/investments/${x}`, params);

    getInvestmentsAccountSummary = (x, entity) => ApiClient().get(`${version}/accounts/my/${entity}/equityinvestments/${x}`, params);

    investIntoAnAccount = (id, data, country, userID) => ApiClient().post(`${version}/accounts/investments/${id}/buyShares/${userID}/${country}`, data, params);

    sellSharesFromInvestment = (id, data, count) => ApiClient().post(`${version}/accounts/investments/${id}/sellShares/${count}`, data, params);

    getAccountProduct = () => ApiClient().get(`${version}/`, params);

    buySomeEquity = (data, id) => ApiClient().post(`${version}/accounts/equity/${id}/buyShares`, data, params);

    sellSomeEquity = (data, id, country) => ApiClient().post(`${version}/accounts/equity/${id}/sellShares/${country}`, data, params);

    getUserSuspenseTransactions = (id, page, count) => ApiClient()
        .get(`${version}/accounts/${id}/transactions/${count && count}?page=${page}`,
            params);

    getUserSavingsTransactions = (id, page, count) => ApiClient()
        .get(`${version}/accounts/${id}/transactions/${count && count}?page=${page}`,
            params);

    getUserEquityTransactions = (id, page, count) => ApiClient()
        .get(`${version}/accounts/${id}/transactions/${count && count}?page=${page}`,
            params);

    getSharesFromStore = (country, page, count) => ApiClient()
        .get(`${version}/shares/${country}/list/${count && count}?page=${page}`,
            params);

    cancelNotifications = (id) => ApiClient().post(`${version}/cancelnotifcations/${id}`, {}, params);

    depositToSuspenseAccount = (data) => ApiClient().post(`${version}/accounts/depositToSuspense/`, data, params);

    depositToSavingsAccount = (data, id, country) => ApiClient().post(`${version}/accounts/${id}/deposit/${country}`, data, params);

    withdrawFromSuspenseAccount = (data, id) => ApiClient().post(`${version}/accounts/${id}/withdraw`, data, params);

    withdrawFromSavingsAccount = (data, id, country) => ApiClient().post(`${version}/accounts/${id}/withdraw/${country}`, data, params);

    getNotifications = () => ApiClient().get(`${version}/alerts/users`, params);

    allocateSomeFunds = (data) => ApiClient().post(`${version}/allocation/allocate/${userId}`, { ...data, country: country }, params);

    allocateSomeFundsSecondaryUser = (data, id) => ApiClient().post(`${version}/allocation/allocate/${id}`, { ...data, country: country }, params);

    getSecondaryAccounts = () => ApiClient().get(`${version}/users/secondaryaccounts/all`, params);

    createSecondaryAccount = (x) => ApiClient().post(`${version}/users/secondaryaccounts/create`, x, params);

    activateAccount = (id, x) => ApiClient().post(`${version}/investments/activate/${id}`, x, params);

    activateInvestmentAccount = (x) => ApiClient().post(`${version}/account/investment/activate`, x, params);

    getGlobalSettings = () => ApiClient().get(`${version}/globalSettings/all`, params);

    getSavingsAccountSummary = (x, entity) => ApiClient().get(`${version}/accounts/my/${entity}/savingsinvestments/${x}`, params);

    getMemberships = () => ApiClient().get(`${version}/memberships`, params);

    createNewSub = (data) => ApiClient().post(`${version}/subscriptions`, data, params);

    fetchSingleSub = (id) => ApiClient().get(`${version}/subscriptions/${id}`, params);

    createTempSubs = (data) => ApiClient().post(`${version}/subscriptions/temporary`, data, params);

    getClientMemberships = () => ApiClient().get(`${version}/memberships/clients/${userId}`, params);

    makePayment = (x) => {
        document.makeP.method = "POST";
        document.makeP.action = ' https://paynow.netcash.co.za/site/paynow.aspx';
        document.makeP.submit();
    };

    purchaseSharesFromMarketplace = (transactionId, userId, country) => ApiClient().get(`${version}/marketplace/${transactionId}/${country}/buyShares/${userId}`, params);

    dashboardTotals = (body) => ApiClient().post(`${version}/dashboard`, body, params);

    dashboardSummary = (country) => ApiClient().get(`${version}/dashboard/summary/${country}`, params);

    updateDebitOrder = (id, body) => ApiClient().post(`${version}/subscriptions/update-sub/${id}`, body, params);

    deleteSubscription = (id) => ApiClient().delete(`${version}/subscriptions/delete-sub/${id}`,params);

    getAccountChart = (country, id, body, page) => ApiClient().post(`${version}/dashboard/account/${country}/${id}/${page}`, body, params);
};

export default new UserService();