import React from 'react';
import ReactDOM from 'react-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import  PaynowReactWrapper from 'paynow-react';
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}




ReactDOM.render(
    <Provider store={configureStore({})}>
        <AlertProvider template={AlertTemplate} {...options}>
              <App />
        </AlertProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an investment-management endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
