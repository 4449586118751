import axios from "axios";

const ApiClient  = () =>{
    
    const {REACT_APP_API_URL} = process.env;
    // REACT_APP_API_UR

    const axiosInstance = axios.create(
        {
            baseURL:window.location.origin.includes("localhost") || window.location.origin.includes("tsigiro.com") ? window.location.origin : REACT_APP_API_URL,
            responseType:'json'
        }
    );

  axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
  axiosInstance.defaults.headers.post['x-access-token'] = '';

  
  axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        const message = error?.response?.data ? JSON.stringify(error?.response?.data?.message) : error.message || error;


        // if (error && error.response && error.response.status === 404) {
        //      message = JSON.stringify(error.response.data.message);
        // } else if (error && error.response && error.response.status === 403) {
        //     message = JSON.stringify(error.response.data.message);

        // } else {
        //     switch (error?.response?.status) {
        //         case 401:
        //                        message = JSON.stringify(error.response.data.message);

        //             break;
        //         case 403:
        //                        message = JSON.stringify(error.response.data.message);

        //             break;
        //         case 404:
        //                         message = JSON.stringify(error.response.data.message);

        //             break;
        //         default: {
        //                         message = JSON.stringify(error.response.data.message);

        //         }
        //     }
            return Promise.reject(message);
        // }
    }
);

    return axiosInstance

}

export default ApiClient;
