import React, { useState } from 'react';
import WalletDO from '../dashboard/Analytics/Overview/WalletDO/WalletDO';
import CardDO from '../dashboard/Analytics/Overview/WalletDO/CardDO';
import { Card, Col, Row } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { getEntityCountry } from '../../api/user/Cookie';

const RecurringInvestment = (props) => {

    const [showWalletDO, setShowWalletDO] = useState(false);
    const [showCardDO, setShowCardDO] = useState(false);
    const country = getEntityCountry();

    return (
        <div style={{marginTop:30}}>
            <WalletDO
                show={showWalletDO}
                onHide={() => setShowWalletDO(!showWalletDO)}
                {...props}
                type="entity-funding"
            />
            <CardDO
                show={showCardDO}
                onHide={() => setShowCardDO(!showCardDO)}
                {...props}
                type="entity-funding"
            />
            <Row>
                <Col lg="6">
                    <Card style={{ cursor: 'pointer', backgroundColor: '#47878A', color: '#fff' }} onClick={() => setShowCardDO(true)}>
                        <Card.Body>
                            {
                                country === "South Africa" && <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <h4 style={{ margin: 0, color: '#fff',fontSize:13 }}>Card Subscription</h4>
                                            <p style={{ margin: 0 ,fontSize:13}}>Automate monthly investment into {props.entity?.entityName}</p>
                                        </div>
                                        <FeatherIcon icon="credit-card" color="#fff" />
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="6">
                    <Card style={{ cursor: 'pointer', backgroundColor: '#47878A', color: '#fff' }} onClick={() => setShowWalletDO(true)}>
                        <Card.Body>
                            {
                                country === "South Africa" && <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <h4 style={{ margin: 0, color: '#fff',fontSize:13 }}>Debit Order</h4>
                                            <p style={{ margin: 0 ,fontSize:13}}>Automate monthly investment into {props.entity?.entityName}</p>
                                        </div>
                                        <FeatherIcon icon="credit-card" />
                                    </Col>
                                </Row>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    )
};

export default RecurringInvestment;