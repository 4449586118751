import React, {useState} from 'react'
import {Alert, Button, Row, Col} from 'react-bootstrap'
import {getDecodedUser, getLoggedInUser} from '../../../../api/user/Cookie'
import ProductsService from '../../../../api/user/ProductsService'
import {FormInput, VerticalForm} from '../../../../components'
import FileUploader from '../../../../components/FileUploader'
import Spinner from '../../../../components/Spinner'

const FundSavings = (props) => {
    const [state,setState] = useState({});
    const token = getDecodedUser();

    const handleSubmit=(x)=>{
        
        x.amount = parseInt(x.amount);
        x.owner = token.id;
        x.to = props?.suspenseId;
        if(props?._id){
        const query = ProductsService.depositToSavingsAccount(x,props._id);
        query.then((x)=>{
            setState({loading:false,error:'Request Successfully Submitted'});
            // document.location.reload();
        });

        query.catch((x)=>{
            setState({loading:false,error:x});
        })
        }
    };

  return (
    <>
     <Row>
        {props?.balanceInRand > 0 && <Col lg="4">
            <h5>Balance In ZAR</h5>
            <p>ZAR {props?.balanceInRand?.toFixed(2) || '0.00' }</p>
        </Col>}
        {props?.balanceInUSD > 0 && <Col lg="4">
            <h5>Balance In USD</h5>
            <p>$ {props?.balanceInUSD?.toFixed(2) || '0.00' }</p>
        </Col>}
        {props?.balanceInUSD > 0 && <Col lg="4">
            <h5>Balance In ZWL</h5>
            <p>ZWL {props?.balanceInZWL?.toFixed(2) || '0.00' }</p>
        </Col>}
    </Row>
    {
        state.error && <Alert>
            {state.error}
        </Alert>
    }
    <VerticalForm
    onSubmit={handleSubmit}
    >
        <FormInput
        type="select"
        name="currency"
        label="Select Currency"
        containerClass='mb-3'
        >
        <option>
            ZWL
        </option>
        <option>
            ZAR
        </option>
        <option>
            USD
        </option>
        </FormInput>
        <FormInput
        type="number"
        label="Enter Amount"
        containerClass='mb-3'
        placeholder='0.00'
        name="amount"
        />
        <Button 
        type="submit"
        style={{marginTop:30,backgroundColor:'rgb(2, 157, 75)',border:'none',width:'100%'}}
        disabled={state.loading}
        >
        
        {
            state.loading ? <Spinner></Spinner> : 'Deposit To Account'
        }   
            
        </Button>
        </VerticalForm>
    </>

  )
}

export default FundSavings