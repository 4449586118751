import React, { useEffect,useState,useRef } from 'react';
import Routes from './routes/Routes';
import { useIdleTimer } from 'react-idle-timer';
import { getGlobalSettings } from './api/user/Cookie';

// Themes
// For Default import Theme.scss
import './assets/scss/Theme.scss';

import './assets/icons/icons/_dripicons.scss';

// import './main.css';
import Root from './routes/Root';
import { Tab } from 'react-bootstrap';
import useDisableNumberInputScroll from './components/DisableInput';

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';


const App = () => {
    
    return (
        <>
            <Tab.Container id="view-accounts" defaultActiveKey="suspense">
                <Routes></Routes>
             </Tab.Container>
        </>
    );
};


export default App;
