import React, { useState } from 'react'
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import ProductsService from '../../api/user/ProductsService';

const DeleteModal = (props) => {

    const [state, setState] = useState({});

    const deleteSubcription = () => {
        ProductsService.deleteSubscription(props._id).then(() => {
            document.location.reload();
        }).catch((x) => {
            setState({ loading: false, error: x });
        })
    };

    return (
        <Modal onHide={props.onHide} show={props.show} centered >
            <Modal.Header closeButton>
                Delete Reccuring Investment
            </Modal.Header>
            <Modal.Body>
                {
                    state.error && <Alert>
                        {state.error}
                    </Alert>
                }
                Are you sure you want to delete this reccuring investment ?
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ backgroundColor: '#F1F5F9', color: '#000', fontSize: 11, border: 'none', marginRight: 10 }} onClick={deleteSubcription}>
                    {state.loading ? <Spinner></Spinner> : 'Delete Reccuring Investment'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModal;