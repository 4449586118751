import {getEntityCountry} from "../api/user/Cookie";
import {getGlobalSettings} from "../api/user/Cookie";

export const DateHandler =(date)=>{
    const months = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'];
                                        
    const minutes = new Date(date).getMinutes() < 10 ? "0"+ new Date(date).getMinutes() : new Date(date).getMinutes();
    const hours = new Date(date).getHours() < 10 ? "0"+ new Date(date).getHours() : new Date(date).getHours();

    const formatedDate = months[new Date(date).getMonth()] + ' '
                                        +new Date(date).getUTCDate() + ', ' 
                                        +new Date(date).getFullYear()
                                        // + " "
                                        // +hours
                                        // +":"
                                        // +minutes

    return formatedDate;
}

export const currency =()=>{

    const country = getEntityCountry();
    if(country === 'South Africa'){
        return 'ZAR';
    }else{
        return 'USD'
    }
}

export const getAge=(dateString)=> {
    
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const transactionId = ()=>{
    const globalName = getGlobalSettings().globalEntityName;

    const stringId = globalName.substring(0,3).toUpperCase();

    const transactionId = stringId + new Date().getFullYear() + '' + new Date().getDate() + ''+ (new Date().getMonth() +1) + '' + new Date().getMinutes() +'' +new Date().getSeconds() + '-' + Math.floor(Math.random()*900) + 100;
    
    return transactionId;
}

export const calculateCharge =(amount,percentage)=>{

    const percent = (((percentage||0)/ 100) * (amount||0)).toFixed(2);

    if(!percent){
        return 0;
    }else{
        return percent;
    }
    
}

export const calculateShares=(amount,sharePrice)=>{

    const shares = (amount||0)*sharePrice

    if(!shares){
        return 0;
    }else{
        return shares.toFixed(2);
    }

}