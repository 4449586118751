import React, { useEffect, useState } from 'react'
import { Accordion, Row, Col, Button, TabContainer, Nav, Card, Table, Pagination } from 'react-bootstrap';
import { getCurrency, getEntityCountry, setGlobalSettings } from '../../api/user/Cookie';
import ProductsService from '../../api/user/ProductsService';
import { currencyFixer } from '../dashboard/Analytics/Product/Utils';
import { generateLastDates, generateLastWeeks, generateLastDays } from '../dashboard/Ecommerce/InvestmentsChart';
import ReactECharts from 'echarts-for-react';
import { DateHandler } from '../../CoolHelpers/CoolHelpers';
import InvestmentEntities from './InvestmentEntities';
import DepositToFunding from './DepositToFunding';
import WithdrawFromAccount from '../dashboard/Analytics/Overview/WithdrawFromAccount';

const lastYear = generateLastDates();
const lastWeeks = generateLastWeeks();
const lastdays = generateLastDays();

const InvestmentManagement = () => {

    const [state, setState] = useState({
        loading: true, selected: 'y12', nav: 'w12',
        data: lastYear,
        country: getEntityCountry()
    });
    const [isSecondary, setIsSecondary] = useState(false);
    const [mainAccount, setMainAccount] = useState([]);
    const [countP, setCountP] = useState([])
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(Infinity);

    const country = getEntityCountry();

    const options = {
        title: {
            text: 'FUNDING ACTIVITY',
            textStyle: {
                fontSize: 12,
                fontFamily: 'Montserrat'
            },
            left: "center",
        },
        showLoading: true,
        legend: { top: 30 },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            // data: state.xdata
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: state.data,
                type: 'line',
                color: '#81D0B9'
            }
        ]
    };

    useEffect(() => {
        setState({ ...state, loading: true });
        const query = ProductsService.getUserSuspenseAccount(country, page, state.data);

        query.then((x) => {
            let pages = Math.floor(x.data.transactionCount / 5) === 0 ? 1 : Math.floor(x.data.transactionCount / 5) + 1;
            paginationHandler(pages);
            setState({
                ...state,
                data: x.data.graphData,
                id: x.data.account._id,
                madeDeposit: x.data?.hasPaidFirstDeposit,
                transactions: x.data.transactions,
                suspenseAccount: x.data.account,
                pendingIn: x.data?.pendingIn[0]?.totalAmount,
                pendingOut: x.data?.pendingOut[0]?.totalAmount,
                withdrawalsTotal: x.data?.withdrawalsTotal[0]?.totalAmount,
                loading: false,
                suspenseAccountBalance: ((x.data?.account?.balance || 0) - (x.data?.pendingOut[0]?.totalAmount || 0)),
            });
            setIsSecondary(x.data?.isSecondary);
            setMainAccount(x.data?.mainAcountOwner);
            setGlobalSettings(x.data.globalSettings[0]);

        });
        query.catch((x) => {
            setState({ ...state, error: x, loading: false });
        });


    }, [page, state.selected]);

    const sortPagination = (paginationItems) => {
        const first5 = paginationItems.slice(0, 5);
        const last5 = paginationItems.slice(-5);

        // If the last 5 pages overlap with the first 5 pages, remove the duplicates
        const overlap = first5.filter((item) => last5.includes(item));
        const uniqueLast5 = last5.filter((item) => !overlap.includes(item));

        let sortedPagination = [...first5, <Pagination.Ellipsis key="ellipsis" />, ...uniqueLast5];

        if (page <= 3) {
            sortedPagination = paginationItems.slice(0, 5 + (page - 1));
        } else if (page >= paginationItems.length - 2) {
            sortedPagination = paginationItems.slice(-5);
        } else {
            sortedPagination = paginationItems.slice(page - 3, page + 2);
            sortedPagination = [paginationItems[0], <Pagination.Ellipsis key="ellipsis" />, ...sortedPagination, <Pagination.Ellipsis key="ellipsis2" />, paginationItems[paginationItems.length - 1]];
        }

        return sortedPagination;
    };

    const paginationHandler = (pages) => {
        let active = state.active;
        setPages(pages);
        let paginationItems = [];
        // let pages = [];
        for (let number = 1; number <= pages; number++) {
            paginationItems.push(
                <Pagination.Item key={number} active={number === active} onClick={(x) => setPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        setCountP(sortPagination(paginationItems));

    };

    return (
        <div style={{ padding: 20 }}>
            <Accordion defaultActiveKey={0} flush   >
                <Accordion.Item eventKey={0} style={{ backgroundColor: 'transparent' }} >
                    <Accordion.Button style={{ backgroundColor: 'transparent' }}  >
                        <Row style={{ width: '100%', fontSize: 11, color: '#000', fontFamily: 'Montserrat' }}>
                            <Col lg="3">
                                <h3 style={{ fontSize: 18, fontWeight: 600, marginBottom: 20, marginTop: 20 }}>
                                    Funding Account
                                </h3>
                            </Col>
                            <Col lg="3">
                                <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                                    BALANCE
                                </p>
                                {state.loading ? <p>-</p> : <p style={{ fontWeight: 600, fontSize: 13, fontFamily: 'Montserrat' }}>{getCurrency()} {currencyFixer(state.suspenseAccountBalance || 0)}</p>}
                            </Col>
                            <Col lg="3">
                                <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                                    PENDING DEPOSITS
                                </p>
                                {state.loading ? <p>-</p> : <p style={{ fontWeight: 600, fontSize: 13 }}>{getCurrency()} {currencyFixer(state.pendingIn || 0)}</p>}

                            </Col>
                            <Col lg="3">
                                <p style={{ fontSize: 13, marginBottom: 20, marginTop: 20, color: '#000', fontFamily: 'Montserrat' }}>
                                    PENDING WITHDRAWALS
                                </p>
                                {state.loading ? <p>-</p> : <p style={{ fontWeight: 600, fontSize: 13 }}>{getCurrency()} {currencyFixer(state.withdrawalsTotal || 0)}</p>}
                            </Col>
                        </Row>
                    </Accordion.Button>
                    <Accordion.Body>
                        <Row>
                            <Col lg="7">
                                <div style={{ display: 'flex', width: 'auto' }} >
                                    <section style={{ width: 'auto', fontSize: 12, width: state.nav === "w12" ? '100px' : 'auto' }} onClick={() => {
                                        setState({ ...state, nav: 'w12', })
                                    }} className={`chat-buttons ${state.nav === "w12" ? "w12" : ""}`} >Chart</section>
                                    <section style={{ width: state.nav === "d12" ? '100px' : 'auto', fontSize: 12 }} onClick={() => {
                                        setState({ ...state, nav: 'd12', })
                                    }} className={`chat-buttons ${state.nav === "d12" ? "d12" : ""}`}>Transactions</section>

                                </div>
                            </Col>
                            {state.nav === "w12" ? <Col lg="2">
                                <div style={{ display: 'flex' }} >
                                    <section onClick={() => {
                                        setState({ ...state, selected: 'd12', data: lastdays })
                                    }} className={`chat-buttons ${state.selected === "d12" ? "d12" : ""}`}>12D</section>
                                    <section onClick={() => {
                                        setState({ ...state, selected: 'w12', data: lastWeeks })
                                    }} className={`chat-buttons ${state.selected === "w12" ? "w12" : ""}`} >12W</section>
                                    <section onClick={() => {
                                        setState({ ...state, selected: 'y12', data: lastYear })
                                    }} className={`chat-buttons ${state.selected === "y12" ? "y12" : ""}`}>1Y</section>
                                </div>
                            </Col> : <Col lg="2"></Col>}
                            <Col lg="3">
                                <div>
                                    {/* <Button
                                        onClick={() => {
                                            setState({ ...state, nav: 'withdraw', })
                                        }}
                                        className="float-end"
                                        style={{ backgroundColor: state.nav === "withdraw" ? '#47878A' : '#F1F5F9', color: state.nav === "withdraw" ? '#fff' : '#000', fontSize: 11, border: 'none', }}
                                    >withdraw</Button> */}
                                    <Button
                                        onClick={() => {
                                            setState({ ...state, nav: 'deposit', })
                                        }}
                                        className="float-end"
                                        style={{ backgroundColor: state.nav === "deposit" ? '#47878A' : '#F1F5F9', color: state.nav === "deposit" ? '#fff' : '#000', fontSize: 11, border: 'none', marginRight: 10 }}
                                    >deposit</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {state.nav === "w12" && <Card style={{ marginTop: 20 }}>
                                <Card.Body>
                                    <ReactECharts showLoading={state.loading} style={{ height: "350px", width: '100%' }} option={options} />
                                </Card.Body>
                            </Card>}
                            {state.nav === "d12" && <Card style={{ marginTop: 20 }}>
                                <Card.Body>
                                    <Col>
                                        <div className="table-responsive table-borderless">
                                            <Table hover>
                                                <thead style={{ backgroundColor: '#F1F5F9', fontSize: 12, border: 'none' }}>
                                                    <tr style={{ border: 'none', borderBottom: 'none' }}>
                                                        <th scope="col">Reference #</th>
                                                        <th scope="col">Created On</th>
                                                        <th scope="col">Approved On</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ height: '20px', overflow: 'scroll', fontSize: 12 }} >
                                                    {(state.transactions || []).map((record, index) => {
                                                        return (
                                                            <tr key={index} onClick={() => {
                                                                // setTransaction(record);
                                                                // setShowTransactionModal(true);
                                                            }}>
                                                                <th scope="row">{record.transactionId}</th>
                                                                <td>{DateHandler(record.dateCreated)}</td>
                                                                <td>{record?.dateApproved ? DateHandler(record?.dateApproved) : 'Pending'}</td>
                                                                <td style={{ width: '200px' }}><span style={{
                                                                    backgroundColor: state.id === record.to ? record.status === 'Pending' ? 'orange' : 'green' : record.status === 'Pending' ? 'orange' : 'red',
                                                                    color: '#fff', padding: 3, borderRadius: 3,
                                                                }}>
                                                                    {record.transactionType}</span></td>
                                                                <td><strong>{record.currency === "Rand" ? "ZAR" : record?.currency}</strong> {currencyFixer(record?.amount)}</td>
                                                                <td>{record.status === "Rejected" ?
                                                                    <span style={{
                                                                        backgroundColor: 'brown',
                                                                        color: '#fff', padding: 3, borderRadius: 3,
                                                                    }}>
                                                                        {record.status}</span> : record.status
                                                                }</td>
                                                                <td>
                                                                    {state.id === record.to ? record.withdrawalTo === "bank" ? 'Outgoing To Bank' : 'Incoming' : 'Outgoing'}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                            <Pagination size="sm" >
                                                {page > 1 && <Pagination.Prev onClick={() => setPage(page - 1)} />}
                                                {countP}
                                                {(page < pages) && <Pagination.Next onClick={() => setPage(page + 1)} />}
                                            </Pagination>
                                        </div>
                                    </Col>
                                </Card.Body>
                            </Card>}
                            {
                                state.nav === "deposit" && <DepositToFunding {...state} />
                            }
                            {/* {
                                state.nav === "withdraw" && <Card style={{marginTop:20}}>
                                    <Card.Body>
                                        <WithdrawFromAccount {...state} id={state.suspenseAccount?._id} />
                                    </Card.Body>
                                </Card>
                            } */}
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <InvestmentEntities />
            </Accordion>
        </div>
    )
}

export default InvestmentManagement;