import React, {useEffect, useRef, useState} from 'react'
import {Button, Row, Col, Alert} from 'react-bootstrap';
import ReactToPrint, {useReactToPrint} from 'react-to-print';
import {DebitOrderForm} from './htmlForm';
import {Zoom} from 'react-toastify';
import UserService from '../../../../../api/user/UserService';
import ProductsService from '../../../../../api/user/ProductsService';
import { transactionId } from '../../../../../CoolHelpers/CoolHelpers';
import { getDecodedUser } from '../../../../../api/user/Cookie';
import FileUploader2 from '../../../../NavBar/Helpers/FileUploader2';
import Spinner from '../../../../../components/Spinner';
import { FormInput, VerticalForm } from '../../../../../components';

const WalletDOSetup = (props) => {
    
    const [state,setState] = useState({loading:true,...props});
    const [address,setAddress] = useState(" ");
    const [phone,setPhone]= useState("");
    const [debitOrder,setDebitOrder] = useState("");
    const componentRef = React.useRef(null);
    const [userID,setUserId] = useState("");

    useEffect(()=>{
        UserService.fetchBankingDetails()
        .then((x)=>{
            //debit order reference id
            setState({...state,...x.data?.bank,loading:false});
        });

        UserService.Me()
        .then((x)=>{
            const addre = x.data.user?.address;
            const addressValue = addre?.address ||"" + " " + addre?.appartmentSuite||"" + " " + addre?.city||"" +" " +  addre?.country||"" + " "  + addre?.state||"";
            setAddress(addressValue);
            setPhone(x.data.user.phone);
            setUserId(x.data.user?._id);
            let debitOrderId = (Math.floor(Math.random()*90000000) + 10000000);
            setDebitOrder(debitOrderId);
        }).catch((x)=>{
            console.log(x);
        });
    },[]);

    const handleClick = useReactToPrint({
        content:() => componentRef.current
    })

    const handleActivationClick =(next)=>{
        setState({...state,loading:true});
        const newSubscription = {
            user:userID,
            subscriptionType:props.type,
            subscriptionReference:debitOrder,
            subscriptionMethod:"debit-order",
            subscriptionFirstPaymentDate:state.firstDate,
            subscriptionDate:state.subscriptionDate,
            bankAccountName:state.accountName,
            subscriptionContactPhone:phone,
            subscriptionAddress:address,
            subscriptionBankName:state.bankName,
            subscriptionTotalAmount:props.amount,
            subscriptionBankBranchNumber:state.branchNumber,
            subscriptionBankBranchName:state.branchName,
            subscriptionBankAccountNumber:state.accountNumber,
            subscriptionBankType:state.typeOfAccount,
            account:props._id
         };

        var formData = new FormData();
        
        (state.files || []).map((file)=>{
            formData.append(`supportingDocuments`, file);
        });
        
        for(var key in newSubscription){
        formData.append(key,newSubscription[key]);
        };

        ProductsService.createNewSub(formData)
        .then((x)=>{
            setState({...state,loading:false,error:null});
            next();
        })
        .catch((x)=>{
            setState({...state,loading:false,error:x});
        })

    };

     const minDate =()=>{
        const date = new Date();
        const month = (date.getMonth() + 1 ) < 10 ? '0' +(date.getMonth() + 1 ) : (date.getMonth() + 1 );
        const min = date.getFullYear() + "-" + month + "-" + (date.getDate()+3);
        return min;
    };

    const minDateValue = minDate();

   return (
    <Row style={{paddingBottom:50}}>
        <Col lg="3">
            <Button onClick={props.prev}>
                Back
            </Button>
        </Col>
        <Col lg="6">
              <h3 style={{fontWeight:600}}>Debit Order Mandate Details</h3>
       <p style={{marginBottom:10}}>
        Please verify the following details as per your user account and complete all fields.
       </p> 
       {
         state.error && <Alert>
            {state.error}
         </Alert>
       }
       {
        state.loading ? <p style={{textAlign:'center'}}><Spinner/></p> :
        <VerticalForm>
            <FormInput
            type='date'
            containerClass='mb-3'
            label="First Debit Order Date"
            max={31}
            min={minDateValue}
            onChange={(x)=>{
                setState({...state,firstDate:x.target.value});
            }}
            />
             <FormInput
            type='select'
            containerClass='mb-3'
            label="Subscription Order Date (of each month)"
            max={31}
            name="subscriptionDate"
            min={0}
            onChange={(x)=>{
                setState({...state,subscriptionDate:x.target.value});
            }}
            >
                <option value="">Select Subscription Date</option>
                <option value="1">1st</option>
                <option value="2">2nd</option>
                <option value="5">5th</option>
                <option value="7">7th</option>
                <option value="15">15th</option>
                <option value="25">25th</option>
                <option value="26">26th</option>
                <option value="30">30th</option>
                <option value="31">31th</option>
            </FormInput>
            <FormInput
            type='text'
            containerClass='mb-3'
            label="Account Holder Name"
            defaultValue={state?.accountName}
            onChange={(x)=>{
                setState({...state,accountName:x.target.value});
            }}
            />
            <FormInput
            type='text'
            containerClass='mb-3'
            label="Contact Number"
            defaultValue={phone}
            onChange={(x)=>{
                setPhone(x.target.value);
            }}
            />
            <FormInput
            type='text'
            containerClass='mb-3'
            label="Address"
            defaultValue={address}
            onChange={(x)=>{
                setAddress(x.target.value)
            }}
            />
            <FormInput
            type='text'
            containerClass='mb-3'
            label="Bank Name"
            defaultValue={state?.bankName}
            onChange={(x)=>{
                setState({...state,bankName:x.target.value});
            }}
            />
            <FormInput
            type='text'
            containerClass='mb-3'
            label="Branch Name and Town"
            defaultValue={state?.branchName}
            onChange={(x)=>{
                setState({...state,branchName:x.target.value});
            }}
            />
            <FormInput
            type='text'
            containerClass='mb-3'
            label="Branch Number"
            name="branchNumber"
            onChange={(x)=>{
                setState({...state,branchNumber:x.target.value});
            }}
            />
            <FormInput
            type='text'
            containerClass='mb-3'
            label="Account Number"
            defaultValue={state?.accountNumber}
            onChange={(x)=>{
                setState({...state,accountNumber:x.target.value});
            }}
            />
             <FormInput
            type="select"
            label="Type Of Account"
            containerClass='mb-3'
            onChange={(x)=>{
                setState({...state,typeOfAccount:x.target.value});
            }}
            >
                <option value="">Select account type</option>
                <option value="savings">Savings</option>
                <option value="current">Current (cheque)</option>
            </FormInput>
            <div>
                <Button onClick={()=>handleActivationClick(props.next)} className='btm-sm'>
                   {
                    state.loading ? <Spinner></Spinner> : 'Complete Debit Order Setup'
                   }
                </Button>
            </div>
        </VerticalForm>
       }
        </Col>
        <Col lg="3">
            {/* <div>
               <div style={{paddingLeft:50,paddingRight:50}} ref={componentRef} dangerouslySetInnerHTML={{ __html: DebitOrderForm({...state,phone:phone,address:address,debitOrder}) }}></div> 
               <Button 
               onClick={handleClick}
                className='btm-sm' style={{marginLeft:50,marginTop:20}}>
                    Download Copy
                </Button>
            </div> */}
        </Col>
    </Row>
  );

}

export default WalletDOSetup